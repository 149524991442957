import React from 'react';
import { ThemeProvider } from 'styled-components';
import variables, { breakpoints } from 'styles/variables';
import mixins from 'styles/mixins';
import media from 'styles/media';

const Theme = ({ children }) => (
    <ThemeProvider
        theme={{
            breakpoints: [`${breakpoints.sm}px`, `${breakpoints.lg}px`],
            ...variables,
            ...mixins,
            ...media,
        }}>
        {children}
    </ThemeProvider>
);
export default Theme;
