import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Config } from 'config/index';
import { EVENT_MAP_CATEGORY } from 'utils/constants';

const gtmId = Config.googleTagManager.gtmId;

export const withPageTracker = (WrappedComponent, options = {}) => {
    const TrackerWrapper = (props) => {
        const addDataLayer = (page) => {
            TagManager.dataLayer({
                gtmId,
                dataLayer: {
                    page,
                    ...options,
                },
                dataLayerName: 'PageDataLayer',
            });
        };
        useEffect(() => {
            const { location: { pathname = '' } = {} } = props;
            if (pathname) {
                addDataLayer(pathname);
            }
        }, []);

        return <WrappedComponent {...props} />;
    };
    return TrackerWrapper;
};

export const trackingModal = (action, label) => {
    TagManager.dataLayer({
        gtmId,
        dataLayer: {
            event: 'Show-Modal',
            category: EVENT_MAP_CATEGORY[window.location.pathname],
            action,
            label,
        },
        dataLayerName: 'PageDataLayer',
    });
};

export const withModalTracker = (WrappedComponent) => {
    const TrackerWrapper = (props) => {
        useEffect(() => {
            if (props.show && props.tracking) {
                const { id, trackingLabel } = props;
                trackingModal(id, trackingLabel);
            }
        }, [props.show, props.tracking]);

        return <WrappedComponent {...props} />;
    };
    return TrackerWrapper;
};

export const withButtonTracker = (WrappedComponent) => {
    const TrackerWrapper = ({ onClick = () => {}, ...rest }) => {
        const addDataLayer = () => {
            const { id, name } = rest;
            TagManager.dataLayer({
                gtmId,
                dataLayer: {
                    event: 'Click-Button',
                    category: EVENT_MAP_CATEGORY[window.location.pathname],
                    action: name,
                    label: id,
                },
                dataLayerName: 'PageDataLayer',
            });
        };

        const onClickEvent = () => {
            addDataLayer();
            onClick();
        };
        
        return <WrappedComponent onClick={onClickEvent} {...rest} />;
    };
    return TrackerWrapper;
};

export default withPageTracker;
