import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import captureSaIdReducer from 'reducers/captureSaIDReducer';
import captureCellphoneReducer from 'reducers/captureCellphoneReducer';
import captureMcqReducer from 'reducers/captureMcqReducer';
import captureCrsReducer from 'reducers/captureCrsReducer';
import capturePinReducer from 'reducers/capturePinReducer';
import captureAddressReducer from 'reducers/captureAddressReducer';
import captureTCReducer from 'reducers/captureTCReducer';
import captureEmailReducer from 'reducers/captureEmailReducer';
import tokenReducer from 'reducers/tokenReducer';
import captureMiwayReducer from 'reducers/captureMiwayReducer';
import modalReducer from './modalReducer';
import captureInsuranceReducer from './captureInsuranceReducer';
import mcqReducer from '../containers/mcq/reducers';
import sofReducer from '../containers/mcq/sof-reducers';
import cellReducer from '../containers/cell/reducers';
import termsReducer from '../containers/terms-and-conditions/reducer';
import saIdReducer from '../containers/enter-id/reducers';
import otpReducer from '../components/otp/reducer';
import emailReducer from '../containers/email/reducer';
import {
    RESET_STATE,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_GOOGLE_TIME_SUCCESS,
    GET_GOOGLE_TIME_ERROR,
} from '../actions/types';

const accessTokenReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ACCESS_TOKEN_SUCCESS:
            return { ...state, data: action.payload, error: undefined };
        case GET_ACCESS_TOKEN_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
};

const googleTimeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_GOOGLE_TIME_SUCCESS:
            return { ...state, data: action.payload, error: undefined };
        case GET_GOOGLE_TIME_ERROR:
            return { ...state, data: undefined, error: action.error };
        default:
            return state;
    }
};

const appReducer = combineReducers({
    form,
    token: accessTokenReducer,
    mcq: mcqReducer,
    sof: sofReducer,
    cell: cellReducer,
    terms: termsReducer,
    saId: saIdReducer,
    otp: otpReducer,
    email: emailReducer,
    time: googleTimeReducer,
    said: captureSaIdReducer,
    cellphone: captureCellphoneReducer,
    mcqs: captureMcqReducer,
    crs: captureCrsReducer,
    pin: capturePinReducer,
    adds: captureAddressReducer,
    insuranceSanlam: captureInsuranceReducer,
    modalState: modalReducer,
    termsConditions: captureTCReducer,
    emailAddress: captureEmailReducer,
    accessToken: tokenReducer,
    insuranceMiway: captureMiwayReducer,
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
