import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    PIN_CREATE,
    PIN_CREATED,
    PIN_ERROR,
    PIN_RESET_STATE,
} from '../../actions/types';

export const createPin = (args, callback) => ({ type: PIN_CREATE, payload: { args, callback } });

export const createPinEpic = (action$) =>
    action$.ofType(PIN_CREATE)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.profile}/${Config.apiVersion}${Config.apiUri.pinCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    appCode: sessionStorage.getItem('appCode'),
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    pin: action.payload.args.pin,
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: PIN_CREATED,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(PIN_ERROR, xhr));
                }));

export const resetState = () => ({ type: PIN_RESET_STATE });
