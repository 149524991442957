import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';

import captureSaIDEpic from 'epics/captureSaIDEpic';
import captureTCEpic from 'epics/captureTCEpic';
import captureCellphoneEpic from 'epics/captureCellphoneEpic';
import captureMcqEpic from 'epics/captureIdMcqEpic';
import captureCrsEpic from 'epics/captureCrsEpic';
import capturePinEpic from 'epics/capturePinEpic';
import captureEmailEpic from 'epics/captureEmailEpic';
import refreshTokenEpic from 'epics/refreshTokenEpic';
import captureMiwayEpic from 'epics/captureMiwayEpic';
import captureInsuranceEpic from './captureInsuranceEpic';
import { createEmailEpic } from '../containers/email/actions';
import { submitCrsEpic } from '../containers/crs/actions';
import { verifyRecaptchaEpic, pullIdResultsEpic } from '../containers/enter-id/actions';
import { acceptTermsEpic } from '../containers/terms-and-conditions/actions';
import { submitMsisdnEpic } from '../containers/cell/actions';
import { generateOtpEpic, verifyOtpEpic } from '../components/otp/actions';
import { createPinEpic } from '../containers/create-pin/actions';
import {
    mcqDataRequestEpic,
    mcqDataSubmitEpic,
    submitSofEpic,
} from '../containers/mcq/mcq-actions';
import {
    GET_ACCESS_TOKEN,
    GET_ACCESS_TOKEN_SUCCESS,
    GET_ACCESS_TOKEN_ERROR,
    GET_GOOGLE_TIME_ERROR,
    GET_GOOGLE_TIME,
    GET_GOOGLE_TIME_SUCCESS,
} from '../actions/types';
import { errorHelper } from '../actions';
import { Config } from '../config';

const accessTokenEpic = (action$) =>
    action$.ofType(GET_ACCESS_TOKEN).mergeMap((action) => {
        let configTokenHeader = {};
        switch (action.payload.access) {
            case 'time':
                configTokenHeader = Config.timeTokenHeaders;
                break;
            case 'address':
                configTokenHeader = Config.addressTokenHeaders;
                break;
            default:
                configTokenHeader = Config.tokenHeaders;
                break;
        }

        return Observable.ajax({
            url: `${Config.rootUrl}/token`,
            method: 'POST',
            crossDomain: true,
            headers: {
                ...configTokenHeader,
                'Correlation-Id': sessionStorage.getItem('correlationId'),
            },
            body: Config.tokenBody,
        })
            .map((response) => {
                action.payload.callback({ ...response, type: action.payload.access });

                return {
                    type: GET_ACCESS_TOKEN_SUCCESS,
                    payload: { ...response, type: action.payload.access },
                };
            })
            .catch(({ xhr }) => {
                action.payload.callback(xhr);

                return Observable.of(errorHelper(GET_ACCESS_TOKEN_ERROR, xhr));
            });
    });

const getGoogleTimeEpic = (action$) =>
    action$.ofType(GET_GOOGLE_TIME).mergeMap((action) =>
        Observable.ajax({
            url: `${Config.rootUrl}/${Config.apiContext.time}/${Config.apiVersion}${Config.apiUri.time}`,
            method: 'GET',
            crossDomain: true,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('timeAccessToken')}`,
                'Correlation-Id': sessionStorage.getItem('correlationId'),
            },
            responseType: 'xml',
        })
            .map((response) => {
                action.payload(response);

                return {
                    type: GET_GOOGLE_TIME_SUCCESS,
                    payload: response,
                };
            })
            .catch(({ xhr }) => {
                action.payload(xhr);

                return Observable.of(errorHelper(GET_GOOGLE_TIME_ERROR, xhr));
            }),
    );

const rootEpic = combineEpics(
    verifyRecaptchaEpic,
    accessTokenEpic,
    // submitSaIdEpic,
    acceptTermsEpic,
    submitMsisdnEpic,
    mcqDataRequestEpic,
    mcqDataSubmitEpic,
    submitSofEpic,
    generateOtpEpic,
    verifyOtpEpic,
    pullIdResultsEpic,
    createPinEpic,
    createEmailEpic,
    getGoogleTimeEpic,
    submitCrsEpic,
    captureInsuranceEpic,
    captureSaIDEpic,
    captureTCEpic,
    captureCellphoneEpic,
    captureMcqEpic,
    captureCrsEpic,
    capturePinEpic,
    captureEmailEpic,
    refreshTokenEpic,
    captureMiwayEpic,
);

export default rootEpic;
