import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    MSISDN_SUBMIT,
    MSISDN_CAPTURE,
    MSISDN_ERROR,
    MSISDN_RESET_STATE,
} from '../../actions/types';

export const submitMsisdn = (args, callback) => ({ type: MSISDN_SUBMIT, payload: { args, callback } });

export const submitMsisdnEpic = (action$) =>
    action$.ofType(MSISDN_SUBMIT)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.cellphone}/${Config.apiVersion}${Config.apiUri.cellphoneCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                    'Step-Up-Token': sessionStorage.getItem('stepUpToken'),
                },
                body: {
                    appCode: sessionStorage.getItem('appCode'),
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    cellphoneNo: action.payload.args.cell.replace(/[\s]/g, ''),
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: MSISDN_CAPTURE,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(MSISDN_ERROR, xhr));
                }));

export const resetState = () => ({ type: MSISDN_RESET_STATE });
