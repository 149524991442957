import { Observable } from 'rxjs';
import axios from 'axios';
import { Config } from '../config';

const getGoogleAppAccessTokenURL = Config.googleAppTokenUrl;

const fetchAddressSuggestionsURL = `${Config.rootUrl}${Config.apiUri.addressSuggestions}`;
const fetchAddressDetailsURL = `${Config.rootUrl}${Config.apiUri.addressDetails}`;
const getInsuranceAppAccessTokenURL = Config.insuranceAppTokenUrl;
const captureConsentURL = `${Config.rootUrl}/${Config.apiContext.insurance}/${Config.apiVersion}${Config.apiUri.captureConsent}`;
const postInsuranceContractURL = Config.insuranceContractUrl;
const getMiwayResultURL = `${Config.rootUrl}/${Config.apiContext.miway}/${Config.apiVersion}${Config.apiUri.miwayCheckEligibility}`;
const postMiwayConsentURL = `${Config.rootUrl}/${Config.apiContext.miway}/${Config.apiVersion}${Config.apiUri.miwayCaptureConsent}`;

export const refreshToken = () =>
    Observable.ajax({
        url: `${Config.rootUrl}/token`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.tokenHeaders,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: { ...Config.tokenBody },
    });

export const refreshTimeToken = () =>
    Observable.ajax({
        url: `${Config.rootUrl}/token`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.timeTokenHeaders,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: { ...Config.tokenBody },
    });

export const verifySAID = ({ response, saId }) =>
    Observable.ajax({
        url: Config.verifyProxy,
        method: 'POST',
        crossDomain: true,
        body: {
            response,
            saId,
        },
        headers: {
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        responseType: 'xml',
    });

export const tCAccept = (payload) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.tncCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: payload,
        responseType: 'xml',
    });

export const captureCellphone = (cellphoneNo) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.cellphone}/${Config.apiVersion}${Config.apiUri.cellphoneCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
            'Step-Up-Token': sessionStorage.getItem('stepUpToken'),
        },
        body: {
            appCode: sessionStorage.getItem('appCode'),
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            cellphoneNo,
        },
        responseType: 'xml',
    });

export const generateOtp = (cell, requestToken) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpGenerate}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
            'Delivery-Method': 'SMS',
            'Send-to': cell,
        },
        body: {
            requestToken,
        },
        responseType: 'xml',
    });

export const captureOTP = (otpCode, requestToken) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.otp}/${Config.apiVersion}${Config.apiUri.otpVerify}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
            'Delivery-Method': 'SMS',
        },
        body: {
            otpCode,
            requestToken,
        },
        responseType: 'xml',
    });

export const pullIdvResult = () =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.aggregation}/${Config.apiVersion}${Config.apiUri.verificationResult}`,
        method: 'GET',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
            appCode: sessionStorage.getItem('appCode'),
            sessionId: sessionStorage.getItem('sessionId'),
        },
        responseType: 'xml',
    });

export const requestMcqData = () =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.mcq}/${Config.apiVersion}${Config.apiUri.mcqGet}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
        },
        responseType: 'xml',
    });

export const captureEmailAddress = (emailAddress) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.emailCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
            emailAddress,
        },
        responseType: 'xml',
    });

export const captureMcq = ({ nameAnswer, creditAnswers }) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.mcq}/${Config.apiVersion}${Config.apiUri.mcqCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
            nameAnswer,
            creditAnswers,
        },
        responseType: 'xml',
    });

export const captureSof = ({ sourceOfFunds, valueOfFunds }) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.sofCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
            sourceOfFunds,
            valueOfFunds,
        },
        responseType: 'xml',
    });

export const captureCrs = ({ crsResponse, incomeTaxRegions }) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.crsCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
            crsResponse,
            incomeTaxRegions,
        },
        responseType: 'xml',
    });

export const getTimeToken = () => {
    return Observable.fromPromise(
        axios.post(
            `${Config.rootUrl}/token`,
            {
                ...Config.tokenBody,
            },
            {
                headers: {
                    ...Config.timeTokenHeaders,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
            },
        ),
    );
};

export const getTime = () =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.time}/${Config.apiVersion}${Config.apiUri.time}`,
        method: 'GET',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('timeAccessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        responseType: 'xml',
    });

export const capturePin = (pin) =>
    Observable.ajax({
        url: `${Config.rootUrl}/${Config.apiContext.profile}/${Config.apiVersion}${Config.apiUri.pinCapture}`,
        method: 'POST',
        crossDomain: true,
        headers: {
            ...Config.headers,
            Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            'Correlation-Id': sessionStorage.getItem('correlationId'),
        },
        body: {
            appCode: sessionStorage.getItem('appCode'),
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            pin,
        },
        responseType: 'xml',
    });

export const getGoogleAppAccessToken = () =>
    Observable.fromPromise(
        axios.get(getGoogleAppAccessTokenURL, {
            headers: Config.headers,
        }),
    );

export const fetchGoogleAddressSuggestion = (input, sessionToken, googleAppAccessToken) =>
    Observable.fromPromise(
        axios.get(`${fetchAddressSuggestionsURL}&input=${input}&sessiontoken=${sessionToken}`, {
            headers: {
                Authorization: `Bearer ${googleAppAccessToken}`,
                'Content-Type': 'application/json',
            },
        }),
    );

export const getPlaceDetails = (placeId, sessionToken, googleAppAccessToken) =>
    Observable.fromPromise(
        axios.get(`${fetchAddressDetailsURL}&place_id=${placeId}&sessiontoken=${sessionToken}`, {
            headers: {
                Authorization: `Bearer ${googleAppAccessToken}`,
                'Content-Type': 'application/json',
            },
        }),
    );

export const getEligibilityCheck = () =>
    Observable.fromPromise(
        axios.post(
            getInsuranceAppAccessTokenURL,
            {
                sessionId: sessionStorage.getItem('sessionId'),
                appCode: sessionStorage.getItem('appCode'),
            },
            {
                headers: {
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
            },
        ),
    );

export const captureConsent = () =>
    Observable.fromPromise(
        axios.post(
            captureConsentURL,
            {
                sessionId: sessionStorage.getItem('sessionId'),
                appCode: sessionStorage.getItem('appCode'),
            },
            {
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessInsuranceToken')}`,
                    'Content-Type': 'application/json',
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
            },
        ),
    );

export const postConsentResult = (payload) =>
    Observable.fromPromise(
        axios.post(
            postInsuranceContractURL,
            { token: sessionStorage.getItem('accessInsuranceToken'), ...payload },
            {
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessInsuranceToken')}`,
                    'Content-Type': 'application/json',
                },
            },
        ),
    );

export const getMiwayCheck = () => {
    const sessionId = parseInt(sessionStorage.getItem('sessionId'), 10);
    const appCode = sessionStorage.getItem('appCode');
    const url = `${getMiwayResultURL}?sessionId=${sessionId}&appCode=${appCode}`;
    return Observable.fromPromise(
        axios.get(url, {
            data: null,
            headers: {
                ...Config.headers,
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                'Correlation-Id': sessionStorage.getItem('correlationId'),
            },
        }),
    );
};

export const captureMiwayConsent = (sessionCode) => {
    const url = postMiwayConsentURL;
    return Observable.fromPromise(
        axios.post(
            url,
            { sessionCode },
            {
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
            },
        ),
    );
};

export default {
    refreshToken,
    refreshTimeToken,
    verifySAID,
    captureCellphone,
    generateOtp,
    captureOTP,
    pullIdvResult,
    requestMcqData,
    captureMcq,
    captureSof,
    captureCrs,
    getTimeToken,
    getTime,
    capturePin,
    getGoogleAppAccessToken,
    fetchGoogleAddressSuggestion,
    getPlaceDetails,
    getEligibilityCheck,
    captureConsent,
    postConsentResult,
    tCAccept,
    captureEmailAddress,
    getMiwayCheck,
    captureMiwayConsent,
};
