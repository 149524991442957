import {
    EMAIL_CREATED,
    EMAIL_ERROR,
    EMAIL_RESET_STATE,
} from '../../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
    case EMAIL_CREATED:
        return { ...state, data: action.payload, error: undefined };
    case EMAIL_ERROR:
        return { ...state, data: undefined, error: action.error };
    case EMAIL_RESET_STATE:
        state = {};
        return state;
    default:
        return state;
    }
}
