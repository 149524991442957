import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { CAPTURE_EMAIL } from 'actions/types';
import { captureEmailSuccess, captureEmailFailed, showGenericErrorModal } from 'actions/index';
import { getErrorCode } from 'classes/helpers';
import { ERROR_CODES } from 'utils/constants';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const captureEmailEpic = (action$, store, { captureEmailAddress }) =>
    action$.ofType(CAPTURE_EMAIL).switchMap(({ payload: emailAddress }) =>
        captureEmailAddress(emailAddress)
            .map(() => captureEmailSuccess())
            .catch((error, source) => {
                const errorCode = getErrorCode(error);
                const status = error.status;
                if (status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_EMAIL);
                }
                if (status === 0) {
                    return Observable.of(
                        showGenericErrorModal({ errorCode: ERROR_CODES.CONNECTION_TIMEOUT }),
                    );
                }
                switch (errorCode) {
                    case ERROR_CODES.INVALID_EMAIL_FORMAT:
                        return Observable.of(captureEmailFailed());
                    default:
                        return Observable.of(showGenericErrorModal({ errorCode }));
                }
            }),
    );

export default combineEpics(captureEmailEpic);
