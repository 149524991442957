import { handleActions } from 'redux-actions';
import { REFRESH_TOKEN, REFRESH_TOKEN_FAILED } from 'actions/types';

const initialState = {
    actionType: '',
    times: 0,
};

const actions = {
    [REFRESH_TOKEN]: ({ actionType, times }, { payload: { actionType: newActionType } }) => ({
        actionType: newActionType,
        times: actionType === newActionType ? times + 1 : 1,
    }),
    [REFRESH_TOKEN_FAILED]: () => ({
        actionType: '',
        times: 0,
    }),
};

export default handleActions(actions, initialState);
