import { handleActions } from 'redux-actions';
import {
    TC_ACCEPT,
    TC_ACCEPT_SUCCESS,
    TC_ACCEPT_FAILED,
    SHOW_GENERIC_ERROR_MODAL,
} from 'actions/types';

const initialState = {
    loading: false,
    success: false,
    failed: false,
};

const actions = {
    [TC_ACCEPT]: (state) => ({
        ...state,
        loading: true,
        failed: false,
    }),
    [TC_ACCEPT_SUCCESS]: (state) => ({
        ...state,
        success: true,
    }),
    [TC_ACCEPT_FAILED]: (state) => ({
        ...state,
        loading: false,
        failed: true,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
