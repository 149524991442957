import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { GET_ELIGIBILITY_CHECK, CAPTURE_CONSENT, POST_CONSENT_RESULT } from 'actions/types';
import { ERROR_CODES, MODAL_ID, CAPTURE_CONSENT_SPECIFIC_ERROR } from 'utils/constants';
import { showGenericErrorModal } from 'actions/index';

import {
    getEligibilityCheckSuccess,
    getEligibilityCheckFailed,
    captureConsentFailed,
    postConsentResult,
    postConsentResultSuccess,
    postConsentResultFailed,
} from '../actions/index';

export const fetchCheckEligibilityEpic = (action$, store, { getEligibilityCheck }) =>
    action$.ofType(GET_ELIGIBILITY_CHECK).switchMap((action) => {
        const { payload } = action;
        return getEligibilityCheck(payload)
            .map((res) => getEligibilityCheckSuccess(get(res, 'data')))
            .catch(() => Observable.of(getEligibilityCheckFailed()));
    });

export const captureConsentEpic = (action$, store, { captureConsent }) =>
    action$.ofType(CAPTURE_CONSENT).switchMap(() => {
        const data = {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
        };
        return captureConsent(data)
            .map((res) => postConsentResult(res.data))
            .catch((error) => {
                const errorCode =
                    get(error, 'response.data.errorCode') ||
                    get(error, 'response.data.errors[0].errorCode') ||
                    get(error, 'response.data.status') ||
                    ERROR_CODES.CAPTURE_CONSENT_GENERIC_ERROR;
                return Observable.of(
                    captureConsentFailed(errorCode),
                    showGenericErrorModal({ id: MODAL_ID.CAPTURE_CONSENT_GENERIC_ERROR, errorCode }),
                );
            });
    });

export const postConsentEpic = (action$, store, { postConsentResult }) =>
    action$.ofType(POST_CONSENT_RESULT).switchMap((action) => {
        const {
            firstName,
            lastName,
            cellphone,
            channel,
            campaign,
            idNumber,
            email,
        } = action.payload;
        const data = {
            'first-name': firstName,
            'last-name': lastName,
            'cell-phone': cellphone,
            channel,
            campaign,
            'id-no': idNumber,
            email,
        };
        return postConsentResult(data)
            .map((res) => postConsentResultSuccess(res.data))
            .catch((error) => {
                const errorCode = get(error, 'response.data.status');
                const isSpecificError = CAPTURE_CONSENT_SPECIFIC_ERROR.includes(errorCode);
                return Observable.of(
                    postConsentResultFailed(errorCode),
                    showGenericErrorModal(
                        isSpecificError
                            ? { errorCode }
                            : { id: MODAL_ID.CAPTURE_CONSENT_GENERIC_ERROR, errorCode },
                    ),
                );
            });
    });

export default combineEpics(fetchCheckEligibilityEpic, captureConsentEpic, postConsentEpic);
