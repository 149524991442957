/*
 * #TODO: investigate why a polyfill is needed to run IE
 */
import 'babel-polyfill';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import 'rxjs';
import reduxThunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from './i18n';
import services from './services/apiService';
import rootEpic from './epics';
import rootReducer from './reducers';
import Routes from './routes';
import 'style/index.css'; // eslint-disable-line import/no-unresolved

const initialState = {};
const enhancers = [];

const epicMiddleware = createEpicMiddleware(rootEpic, {
    dependencies: services,
});

const middleware = [reduxThunk, epicMiddleware];

if (process.env.NODE_ENV === 'development') {
    const { devToolsExtension } = window;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

ReactDOM.render(
    <>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </I18nextProvider>
        </Provider>
    </>,
    document.getElementById('root'),
);
