import { handleActions } from 'redux-actions';
import {
    GET_TIME_TOKEN,
    GET_TIME,
    GET_TIME_SUCCESS,
    CAPTURE_PIN,
    CAPTURE_PIN_SUCCESS,
    SHOW_GENERIC_ERROR_MODAL,
} from 'actions/types';

const initialState = {
    loading: false,
    time: '',
    getTimeSuccess: false,
    capturePinSuccess: false,
};

const actions = {
    [GET_TIME_TOKEN]: (state) => ({
        ...state,
        loading: true,
    }),
    [GET_TIME]: (state) => ({
        ...state,
        loading: true,
        getTimeSuccess: false,
    }),
    [GET_TIME_SUCCESS]: (state, { payload }) => ({
        ...state,
        time: payload,
        getTimeSuccess: true,
    }),
    [CAPTURE_PIN]: (state) => ({
        ...state,
        getTimeSuccess: false,
        capturePinSuccess: false,
    }),
    [CAPTURE_PIN_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        capturePinSuccess: true,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
