import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { REQUEST_MCQ_DATA, CAPTURE_MCQ, CAPTURE_SOF } from 'actions/types';
import {
    requestMcqDataSuccess,
    captureMcqSuccess,
    captureMcqFailed,
    captureSofSuccess,
    showGenericErrorModal,
} from 'actions/index';
import { getErrorCode, dataResponse, generateRetryStrategy } from 'classes/helpers';
import { MODAL_ID, ERROR_CODES, FIDES_RESPONSE_STATUS } from 'utils/constants';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const handleErrorCode = (errorCode) => {
    let id = '';
    switch (errorCode) {
        case ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_DATA:
        case ERROR_CODES.FIDES_CREDIT_MCQ_ID_NOT_FOUND:
        case ERROR_CODES.FIDES_CREDIT_MCQ_ID_BLOCKED:
        case ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA:
        case ERROR_CODES.FIDES_OTHER_UNKNOWN_ERROR:
            id = MODAL_ID.ERROR_MCQ_CRITICAL;
            break;
        case ERROR_CODES.FIDES_CREDIT_MCQ_GENERIC_ERROR:
            id = MODAL_ID.ERROR_MCQ_NON_CRITICAL;
            break;
        default:
            break;
    }
    return Observable.of(
        showGenericErrorModal({ id, errorCode: typeof errorCode === 'string' ? errorCode : '' }),
    );
};

export const requestMcqEpic = (action$, store, { requestMcqData }) =>
    action$.ofType(REQUEST_MCQ_DATA).mergeMap((action) =>
        requestMcqData(action.payload)
            .map((res) => {
                const data = dataResponse(get(res, 'xhr.response', {}));
                return requestMcqDataSuccess(data);
            })
            .retryWhen(generateRetryStrategy())
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, REQUEST_MCQ_DATA);
                }
                const errorCode = getErrorCode(error);
                return handleErrorCode(errorCode);
            }),
    );

export const captureMcqEpic = (action$, store, { captureMcq }) =>
    action$.ofType(CAPTURE_MCQ).mergeMap(({ payload }) =>
        captureMcq(payload)
            .map((res) => {
                const data = dataResponse(get(res, 'xhr.response', {}));
                const status = data.status;
                return status === FIDES_RESPONSE_STATUS.ACCEPTED
                    ? captureMcqSuccess()
                    : captureMcqFailed();
            })
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_MCQ);
                }
                const errorCode = getErrorCode(error);
                return handleErrorCode(errorCode);
            }),
    );

export const captureSofEpic = (action$, store, { captureSof }) =>
    action$.ofType(CAPTURE_SOF).mergeMap(({ payload }) =>
        captureSof(payload)
            .map(() => captureSofSuccess())
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_SOF);
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export default combineEpics(requestMcqEpic, captureMcqEpic, captureSofEpic);
