import {
    MSISDN_CAPTURE,
    MSISDN_ERROR,
    MSISDN_RESET_STATE,
} from '../../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
    case MSISDN_CAPTURE:
        return { ...state, data: action.payload, error: undefined };
    case MSISDN_ERROR:
        return { ...state, data: undefined, error: action.error };
    case MSISDN_RESET_STATE:
        state = {};
        return state;
    default:
        return state;
    }
}
