import { handleActions } from 'redux-actions';
import {
    CAPTURE_CELLPHONE,
    CAPTURE_CELLPHONE_SUCCESS,
    CAPTURE_CELLPHONE_FAILED,
    RESET_RESEND_OTP_ATTEMPT,
    GENERATE_OTP,
    GENERATE_OTP_SUCCESS,
    CAPTURE_OTP,
    CAPTURE_OTP_SUCCESS,
    CAPTURE_INVALID_OTP,
    CAPTURE_OTP_EXPIRED,
    PULL_ID_RESULTS,
    PULL_ID_RESULTS_SUCCESS,
    PULL_ID_RESULTS_FAILED,
    SHOW_GENERIC_ERROR_MODAL,
    CLEAR_CAPTURE_CELLPHONE_ERROR_CODE,
} from 'actions/types';

const initialState = {
    loading: false,
    captureCellphoneSuccess: false,
    captureCellphoneFailed: false,
    otpAttempts: 0,
    generateOtpSuccess: false,
    captureOTPSuccess: false,
    pullIdvSuccess: false,
    invalidOTP: false,
    OTPExpired: false,
    stepUpToken: '',
    decisionCode: undefined,
    errorCode: undefined,
};

const actions = {
    [CAPTURE_CELLPHONE]: (state) => ({
        ...state,
        loading: true,
        captureCellphoneSuccess: false,
        captureCellphoneFailed: false,
        generateOtpSuccess: false,
        otpAttempts: 0,
        errorCode: undefined,
    }),
    [CAPTURE_CELLPHONE_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        captureCellphoneSuccess: true,
        captureCellphoneFailed: false,
        generateOtpSuccess: false,
        errorCode: undefined,
    }),
    [CAPTURE_CELLPHONE_FAILED]: (state, { payload }) => ({
        ...state,
        loading: false,
        generateOtpSuccess: false,
        captureCellphoneFailed: true,
        captureCellphoneSuccess: false,
        errorCode: payload,
    }),
    [GENERATE_OTP]: ({ otpAttempts, ...rest }) => ({
        ...rest,
        loading: true,
        generateOtpSuccess: false,
        otpAttempts: otpAttempts + 1,
        OTPExpired: false,
    }),
    [GENERATE_OTP_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        generateOtpSuccess: true,
    }),
    [RESET_RESEND_OTP_ATTEMPT]: (state) => ({
        ...state,
        otpAttempts: 0,
        invalidOTP: false,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
    [CAPTURE_OTP]: (state) => ({
        ...state,
        loading: true,
        invalidOTP: false,
        OTPExpired: false,
        stepUpToken: '',
        errorCode: undefined,
    }),
    [CAPTURE_OTP_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        captureOTPSuccess: true,
        stepUpToken: payload,
        errorCode: undefined,
    }),
    [CAPTURE_INVALID_OTP]: (state) => ({
        ...state,
        loading: false,
        invalidOTP: true,
    }),
    [CAPTURE_OTP_EXPIRED]: (state) => ({
        ...state,
        loading: false,
        OTPExpired: true,
    }),
    [PULL_ID_RESULTS]: (state) => ({
        ...state,
        loading: true,
        pullIdvSuccess: false,
        decisionCode: undefined,
    }),
    [PULL_ID_RESULTS_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        pullIdvSuccess: true,
    }),
    [PULL_ID_RESULTS_FAILED]: (state, { payload: { decisionCode } }) => ({
        ...state,
        loading: false,
        pullIdvSuccess: false,
        decisionCode,
    }),
    [CLEAR_CAPTURE_CELLPHONE_ERROR_CODE]: (state) => ({
        ...state,
        errorCode: undefined,
        generateOtpSuccess: false,
    }),
};

export default handleActions(actions, initialState);
