import { css } from 'styled-components';
import { breakpoints } from 'styles/variables';

const sizes = {
    smallMobile: breakpoints.xs,
    mobile: breakpoints.sm,
    tablet: breakpoints.md,
    desktop: breakpoints.lg,
};
export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${sizes[label]}px) {
            ${css(...args)};
        }
    `;
    return acc;
}, {});
