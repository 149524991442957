import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAILED } from 'actions/types';
import {
    showGenericErrorModal,
    refreshToken,
    refreshTokenSuccess,
    refreshTokenFailed,
} from 'actions/index';
import { getErrorCode, dataResponse } from 'classes/helpers';

export const generateRefreshTokenStrategy = (action$, source, actionType, tokenType) => {
    return action$
        .ofType(REFRESH_TOKEN_SUCCESS)
        .takeUntil(action$.ofType(REFRESH_TOKEN_FAILED))
        .take(1)
        .mergeMapTo(source)
        .merge(Observable.of(refreshToken({ actionType, tokenType })));
};

export const refreshTokenEpic = (action$, store, { refreshToken, refreshTimeToken }) =>
    action$.ofType(REFRESH_TOKEN).mergeMap(({ payload: { tokenType } }) =>
        (tokenType === 'time' ? refreshTimeToken() : refreshToken())
            .mergeMap((res) => {
                const data = dataResponse(get(res, 'xhr.response', {}));
                const state = store.getState();
                sessionStorage.setItem('accessToken', data.access_token);
                if (get(state, 'accessToken.times') === 3) {
                    return Observable.of(showGenericErrorModal(), refreshTokenFailed());
                }
                return Observable.of(refreshTokenSuccess());
            })
            .catch((error) => {
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }), refreshTokenFailed());
            }),
    );

export default combineEpics(refreshTokenEpic);
