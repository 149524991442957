import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { GET_ELIGIBILITY_CHECK_MIWAY, CAPTURE_CONSENT_MIWAY } from 'actions/types';
import { ERROR_CODES, MODAL_ID } from 'utils/constants';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

import {
    getEligibilityCheckMiwaySuccess,
    getEligibilityCheckMiwayFailed,
    captureConsentMiwaySuccess,
    captureConsentMiwayFailed,
    showGenericErrorModal,
} from 'actions/index';

export const getMiwayCheckEpic = (action$, store, { getMiwayCheck }) =>
    action$.ofType(GET_ELIGIBILITY_CHECK_MIWAY).switchMap(() => {
        return getMiwayCheck()
            .map((res) => getEligibilityCheckMiwaySuccess(get(res, 'data')))
            .catch(() => Observable.of(getEligibilityCheckMiwayFailed()));
    });

export const captureMiwayConsentEpic = (action$, store, { captureMiwayConsent }) =>
    action$.ofType(CAPTURE_CONSENT_MIWAY).switchMap((action) => {
        const { sessionCode } = action.payload;
        return captureMiwayConsent(sessionCode)
            .map((res) => captureConsentMiwaySuccess(res.data))
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_CONSENT_MIWAY);
                }
                const errorCode =
                    get(error, 'response.data.errorCode') ||
                    get(error, 'response.data.errors[0].errorCode');
                return Observable.of(
                    captureConsentMiwayFailed(errorCode),
                    showGenericErrorModal(
                        errorCode === ERROR_CODES.CAPTURE_CONSENT_MIWAY_GENERIC_ERROR
                            ? { id: MODAL_ID.CAPTURE_CONSENT_MIWAY_GENERIC_ERROR, errorCode }
                            : { id: MODAL_ID.CAPTURE_CONSENT_MIWAY_ERROR, errorCode },
                    ),
                );
            });
    });

export default combineEpics(getMiwayCheckEpic, captureMiwayConsentEpic);
