import { handleActions } from 'redux-actions';
import {
    CAPTURE_CRS,
    CAPTURE_CRS_SUCCESS,
    PULL_ID_RESULTS,
    PULL_ID_RESULTS_SUCCESS,
    SHOW_GENERIC_ERROR_MODAL,
    SHOW_MODAL,
} from 'actions/types';
import { MODAL_ID, GET_DECISION } from 'utils/constants';

const initialState = {
    loading: false,
    captureCrsSuccess: false,
    pullIdvSuccess: false,
    pullIdvAttempt: 0,
    idvResult: {},
};

const actions = {
    [CAPTURE_CRS]: (state) => ({
        ...state,
        loading: true,
        captureCrsSuccess: false,
        pullIdvAttempt: 0,
    }),
    [CAPTURE_CRS_SUCCESS]: (state) => ({
        ...state,
        captureCrsSuccess: true,
    }),
    [PULL_ID_RESULTS]: ({ pullIdvAttempt, ...rest }) => ({
        ...rest,
        pullIdvSuccess: false,
        pullIdvAttempt: pullIdvAttempt + 1,
    }),
    [PULL_ID_RESULTS_SUCCESS]: (state, { payload: { idvResult = {}, pullType } }) => ({
        ...state,
        loading: false,
        pullIdvSuccess: pullType === GET_DECISION.PULL_VERIFICATION_RESULT_2,
        idvResult,
    }),
    [SHOW_MODAL]: ({ loading, ...rest }, { payload: { id } }) => ({
        ...rest,
        loading: id === MODAL_ID.PROFILE_CHECK_LOADING ? true : loading,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
