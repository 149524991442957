export const ERROR_CODES = {
    // capture said
    INVALID_ID_FORMAT: '0409001',
    SAID_IN_BLACKLIST: '0409062',
    SAID_NOT_INWHITELIST: '0409053',
    EXISTING_SAID: '0409003',
    SAID_FAIL_MCQ_MAX_ATTEMPT: '0409002',
    // capture cellphone
    REQUIRE_CAPTURE_OTP: '0407010',
    INVALID_CELLPHONE_FORMAT: '0409008',
    EXISTING_CELLPHONE: '0409007',
    CANNOT_SEND_OTP: '0407001',
    INVALID_OTP: '0407002',
    VERIFY_OTP_EXPIRED: '0407003',
    VERIFY_OTP_MAX_ATTEMPTS: '0407005',
    CELLPHONE_FAIL_MCQ_MAX_ATTEMPT: '0409055',
    // pull idv
    IDV_VERIFIED: '0409010',
    FAILED_IDV_USER: '0409018',
    IDN_BLOCKED: '0409072',
    FAILED_ON_BOARDING_AGE_REQUIREMENT: '0409009',
    DECEASED_USER: '0409011',
    PERPETRATOR_USER: '0409019',
    SAID_NOT_FOUND: '0409026',
    SAID_IDV_RETRY: '0409012',
    // capture mcq
    FIDES_CREDIT_MCQ_INSUFFICENT_DATA: '0609007',
    FIDES_CREDIT_MCQ_ID_NOT_FOUND: '0609009',
    FIDES_CREDIT_MCQ_ID_BLOCKED: '0609010',
    FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA: '0609011',
    FIDES_OTHER_UNKNOWN_ERROR: '0609012',
    FIDES_CREDIT_MCQ_GENERIC_ERROR: '0609008',
    BLANK_NAME_MCQ: '0609013',
    BLANK_CREDIT_MCQ: '0609014',
    EMPTY_NAME_MCQ: '0609015',
    EMPTY_CREDIT_MCQ: '0609016',
    // Email
    INVALID_EMAIL_FORMAT: '0403006',

    // capture consent
    CAPTURE_CONSENT_REACHED_LIMIT: 429,
    CAPTURE_CONSENT_NOT_APPROVED: 403,
    CAPTURE_CONSENT_GENERIC_ERROR: '0409006',
    CAPTURE_CONSENT_MIWAY_GENERIC_ERROR: '0456001',

    // common
    CONNECTION_TIMEOUT: '0',
    CAPTURE_ADDRESS_GENERIC_ERROR: '0409006',
};

export const MODAL_ID = {
    // capture SAID
    ERROR_INVALID_SAID: 'errorInvalidSaId',
    ERROR_SAID_INBLACKLIST: 'errorSAIDInBlacklist',
    ERROR_SAID_NOT_INWHITELIST: 'errorSAIDNotInWhitelist',
    ERROR_EXISTING_SAID: 'errorExistingSaId',
    ERROR_SAID_FAIL_MCQ_MAX_ATTEMPT: 'errorSAIDFailMcqMaxAttempt',

    // capture cellphone
    OTP: 'otp',
    ERROR_INVALID_CELLPHONE: 'errorInvalidCellphone',
    ERROR_EXISTING_CELLPHONE: 'errorExistingCellphone',
    ERROR_FAIL_SEND_OTP: 'errorFailSendOTP',
    OTP_EXPIRED: 'otpExpired',
    OTP_RESEND_LIMIT: 'otpResendLimit',
    VERIFY_OTP_EXPIRED: 'verifyOtpExpired',
    VERIFY_OTP_FAIL: 'verifyOTPFail',
    ERROR_CELLPHONE_FAIL_MCQ_MAX_ATTEMPT: 'errorCellphoneFailMCQMaxAttempt',

    // pull idv
    SAID_NOT_FOUND: 'SaIdNotFoundInDHA',
    ERROR_SAID_PERPETRATOR: 'errorSAIDPerpetrator',
    ERROR_SAID_DECEASED: 'idvErrorSAIDDeceased',
    IDN_BLOCKED: 'IDNBlocked',
    ERROR_FAIL_CREATE_PROFILE: 'idvErrorFailCreateProfile',
    ERROR_SAID_UNDERAGE: 'errorSaIdUnderage',
    ERROR_SAID_IDV_RETRY: 'idvErrorSAIDRetry',
    ERROR_SAID_IDV_RETRY_LIMIT: 'idvErrorSAIDRetryLimit',

    // capture mcq
    ERROR_MCQ_CRITICAL: 'errorMCQCritical',
    ERROR_MCQ_NON_CRITICAL: 'errorMCQNonCritical',

    // capture crs
    INFO_CONFIRM_LIABLE: 'infoConfirmLiable',

    // capture address
    POR_NOT_MATCH: 'porNotMatch',

    // common
    ERROR_CONNECTION_TIMEOUT: 'errorConnectionTimeout',

    // Email
    ERROR_INVALID_EMAILL: 'errorInvalidEmail',

    // Consent
    POST_CONSENT_SUCCESSFUL: 'postConsentSuccessful',
    CAPTURE_CONSENT_REACHED_LIMIT: 'captureConsentErrorLimit',
    CAPTURE_CONSENT_NOT_APPROVED: 'captureConsentErrorNotApproved',
    CAPTURE_CONSENT_GENERIC_ERROR: 'captureConsentErrorGeneric',

    // Miway
    CAPTURE_CONSENT_MIWAY_SUCCESS: 'captureConsentMiwaySuccessful',
    CAPTURE_CONSENT_MIWAY_ERROR: 'captureConsentMiwayError',
    CAPTURE_CONSENT_MIWAY_GENERIC_ERROR: 'captureConsentMiwayGenericError',
    MIWAY_BENEFIT_MODAL: 'miwayBenefitModal',

    WARNING_OTP_WILL_EXPIRE: 'warningOTPWillExpire',
    ERROR_EXPIRED_OTP: 'errorExpiredOTP',
    ERROR_FAIL_VERIFY_OTP_MAX_ATTEMPT: 'errorFailVerifyOTPMaxAttempt',
    INFO_ENTER_OTP: 'infoEnterOTP',
    ERROR_MCQ: 'errorMCQ',
    ERROR_MCQ_CONFIRM: 'errorMCQConfirm',
    ERROR_MCQ_BLANKNAME_OR_BLANKCREDIT: 'errorMCQBlankNameOrBlankCredit',
    WARNING_REACHED_TIMEOUT: 'warningReachedTimeout',
    WARNING_OTP_RESEND_LIMIT: 'warningOTPResendLimit',
    ERROR_INVALID_PIN: 'errorInvalidPIN',
    ERROR_PIN_NOT_MATCH: 'errorPINNotMatch',
    INFO_SMART_SHOPPER_MODAL: 'infoSmartShopperModal',
    INFO_MARKETING_MODAL: 'infoMarketingModal',
    INFO_DECLINE_MODAL: 'infoDeclineModal',
    IDV_LOADING: 'idvLoading',
    ERROR_MODAL: 'errorModal',
    BLOCKED_SAID: 'blockedSaId',
    PROFILE_CHECK_RETRY_LIMIT: 'profileCheckRetryLimit',
    PROFILE_CHECK_LOADING: 'profileCheckLoading',
    PROFILE_CHECK_RETRY: 'profileCheckRetry',
    EXPIRED: 'expired',
    RESEND: 'resend',
    INVALID: 'invalid',
    VERIFY: 'verify',
    SMART_SHOPPER: 'smartShopper',
    MARKETING: 'marketing',
    DECLINE: 'decline',
    CAPTURE_ADDRESS_ERROR: 'captureAddressError',
};

export const CAPTURE_CONSENT_SPECIFIC_ERROR = [
    `${ERROR_CODES.CAPTURE_CONSENT_REACHED_LIMIT}`,
    `${ERROR_CODES.CAPTURE_CONSENT_NOT_APPROVED}`,
];

export const MCQ = {
    FIDES_ERROR_CODES: {
        CRITICAL: [
            ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_DATA,
            ERROR_CODES.FIDES_CREDIT_MCQ_ID_NOT_FOUND,
            ERROR_CODES.FIDES_CREDIT_MCQ_ID_BLOCKED,
            ERROR_CODES.FIDES_CREDIT_MCQ_INSUFFICENT_CREDIT_DATA,
            ERROR_CODES.FIDES_OTHER_UNKNOWN_ERROR,
        ],
        NON_CRITICAL: [ERROR_CODES.FIDES_CREDIT_MCQ_GENERIC_ERROR],
    },
};

export const CELLPHONE = {
    ID_VERIFICATION_ERROR_CODES: {
        PULL_ID_STEP_ONE_CRITICAL: [
            ERROR_CODES.FAILED_ON_BOARDING_AGE_REQUIREMENT,
            ERROR_CODES.DECEASED_USER,
            ERROR_CODES.PERPETRATOR_USER,
            ERROR_CODES.SAID_NOT_FOUND,
        ],
    },
};

export const FIDES_RESPONSE_STATUS = {
    DECLINED: 'Declined',
    ACCEPTED: 'Accepted',
    DONE: 'Done',
};

export const SAID_MODAL_LIST = [
    MODAL_ID.ERROR_EXISTING_SAID,
    MODAL_ID.ERROR_INVALID_SAID,
    MODAL_ID.ERROR_SAID_UNDERAGE,
    MODAL_ID.ERROR_FAIL_CREATE_PROFILE,
    MODAL_ID.ERROR_SAID_DECEASED,
    MODAL_ID.ERROR_SAID_IDV_RETRY,
    MODAL_ID.IDV_LOADING,
    MODAL_ID.ERROR_SAID_PERPETRATOR,
    MODAL_ID.IDN_BLOCKED,
    MODAL_ID.SAID_NOT_FOUND,
    MODAL_ID.ERROR_SAID_FAIL_MCQ_MAX_ATTEMPT,
];

export const GET_DECISION = {
    PULL_VERIFICATION_RESULT_1: 'pullVerificationResult1',
    PULL_VERIFICATION_RESULT_2: 'pullVerificationResult2',
    PULL_VERIFICATION_RESULT_3: 'pullVerificationResult3',
};

export const EVENT_MAP_CATEGORY = {
    '/': 'Home',
    '/enter-id': 'RegisterSAID (/enter-id)',
    '/terms-and-conditions': 'T&C (/terms-and-conditions)',
    '/cell/capture': 'Capture CellPhone(/cell/capture)',
    '/mcq/start': 'MCQ Start (/mcq/start)',
    '/mcq/select': 'MCQ Answer (/mcq/select)',
    '/mcq/confirm': 'MCQ Confirm (/mcq/confirm)',
    '/mcq/sof': 'Source Of Funds (/mcq/sof)',
    '/crs': 'CRS (/crs)',
    '/unsuccessful': 'MCQ Decline(/unsuccessful)',
    '/create-pin': 'Create Pin (/create-pin)',
    '/email': 'Capture Email (/email)',
    '/pending': 'Profile Status Pending Approval (/pending)',
    '/successful': 'Origination Complete (/successful)',
    '/miway': 'Miway Insurance Benefit (/miway)',
};

export const CAPTURE_POR_MCQ_STATUS = {
    ACCEPTED: 'Accepted',
    DECLINED: 'Declined',
};

export const PLACE_TYPES_MAPPING = {
    ADMINISTRATIVE_AREA_LEVEL_1: 'administrative_area_level_1',
    LOCALITY: 'locality',
    SUBLOCALITY: 'sublocality',
    STREET_NUMBER: 'street_number',
    STREET_ADDRESS: 'street_address',
    ROUTE: 'route',
    PREMISE: 'premise',
    SUBPREMISE: 'subpremise',
    POSTAL_CODE: 'postal_code',
    COUNTRY: 'country',
};

export const SA_PROVINCES = {
    'Eastern Cape': 'Eastern Cape',
    'Free State': 'Free State',
    Gauteng: 'Gauteng',
    'Kwa-Zulu Natal': 'Kwa-Zulu Natal',
    Limpopo: 'Limpopo',
    Mpumalanga: 'Mpumalanga',
    'North West': 'North West',
    'Northern Cape': 'Northern Cape',
    'Western Cape': 'Western Cape',
};

export const COUNTRIES = [
    'Albania',
    'Algeria',
    'Argentina',
    'Australia',
    'Austria',
    'Bahrain',
    'Belarus',
    'Belgium',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Brazil',
    'Bulgaria',
    'Canada',
    'Chile',
    'China',
    'Colombia',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Guatemala',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iraq',
    'Ireland',
    'Israel',
    'Italy',
    'Japan',
    'Jordan',
    'Kuwait',
    'Latvia',
    'Lebanon',
    'Libya',
    'Lithuania',
    'Luxembourg',
    'Macedonia',
    'Malaysia',
    'Malta',
    'Mexico',
    'Montenegro',
    'Morocco',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Norway',
    'Oman',
    'Panama',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Romania',
    'Russia',
    'Saudi Arabia',
    'Serbia',
    'Serbia and Montenegro',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'South Africa',
    'South Korea',
    'Spain',
    'Sudan',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Thailand',
    'Tunisia',
    'Turkey',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Venezuela',
    'Vietnam',
    'Yemen',
];

export const DEFAULT_COUNTRY = 'South Africa';

export const ADDRESS_FIELDS_MAPPING = {
    building: 'building_number_and_name',
    street: 'street_number_and_name',
    suburb: 'suburb',
    city: 'town',
    province: 'province',
    country: 'country',
    postalCode: 'postal_code',
};

export const BUTTON_ID = {
    START_NOW: 'get-start-now',
    ENTER_ID_NEXT: 'enter-id-next',
    TERMS_AND_CONDITIONS_ACCEPT: 'terms-and-conditions-accept',
    TERMS_AND_CONDITIONS_DECLINE: 'terms-and-conditions-decline',
    CELLPHONE_NEXT: 'cellphone-next',
    OTP_CHANGE_CELLPHONE: 'otp-change-cellphone',
    OTP_CONFIRM: 'otp-confirm',
    MCQ_START_NEXT: 'mcq-start-next',
    MCQ_NEXT: 'mcq-next',
    CRS_NEXT: 'crs-next',
    CRS_CONFIRMLIABLE_CONFIRM_NO: 'crs-confirmLiable-confirmNo',
    CRS_CONFIRMLIABLE_CONFIRM_YES: 'crs-confirmLiable-confirmYes',
    CREATE_PIN_NEXT: 'create-pin-next',
    EMAIL_NEXT: 'email-next',
    EMAIL_SKIP: 'email-skip',
    PENDING_OK: 'pending-ok',
    SUSSESSFUL_LOGIN: 'successful-login',
    SUCCESSFUL_FAQ: 'successful-faq',
    UNSUCCESSFUL_OK: 'unsuccessful-ok',
    CAPTURE_POR_NEXT: 'capture-por-next',
    CAPTURE_ADDRESS_NEXT: 'capture-address-next',
    CAPTURE_ADDRESS_CANCEL: 'capture-address-cancel',
    CHANGE_ADDRESS: 'change-address',
    CONFIRM_ADDRESS_NEXT: 'confirm-address-next',
    TIMEOUT_CONTINUE: 'timeout-continue',
    TIMEOUT_EXIT: 'timeout-exit',
    SUBMIT_INSTANT_APPLICATION: 'submit-instant-application',
    GET_INSTANT_COVER: 'Miway-check-instant-cover',
    NOT_INTERESTED: 'not-interested',
    NEXT_SANLAM: 'next-sanlam',
    GOT_IT: 'got-it',
    BACK_LOGIN: 'back-to-login-page',
    CONFIRM_AGE: 'confirm-age',
    CANCEL_CONFIRM_AGE: 'cancel-confirm-age',
    NEXT_MIWAY: 'next-miway',
    LEARN_MORE_MIWAY: 'learn-more-miway',
    CLOSE_MODAL_BENEFIT: 'close-modal-benefit',
    G_Play_Button: 'G_Play_Button',
    A_Store_Button: 'A_Store_Button',
    H_App_Button: 'H_App_Button',
    Web_Button: 'Web_Button',
    Mobile_Web_Button: 'Mobile_Web_Button',
    Dynamic_App_Top_Button: 'Dynamic_App_Top_Button',
    Dynamic_App_Top_Middle: 'Dynamic_App_Top_Middle',
    FIND_A_KIOSK_NEAR_YOU: 'find-a-kiosk-near-you',
    FIND_A_PNP_OR_BOXER_NEAR_YOU: 'find-a-pnp-or-boxer-near-you',
    CONTINUE_ACTIVE: 'continue-active-account',
};

export const BUTTON_LABEL = {
    START_NOW: 'Start Now',
    ENTER_ID_NEXT: 'Enter ID Next',
    TERMS_AND_CONDITIONS_ACCEPT: 'T&C Accept',
    TERMS_AND_CONDITIONS_DECLINE: 'T&C Decline',
    CELLPHONE_NEXT: 'Cellphone Next',
    OTP_CHANGE_CELLPHONE: 'OTP Change Cellphone',
    OTP_CONFIRM: 'OTP Confirm',
    OTP_EXPIRED_RESEND: 'OTP Expired Resend',
    OTP_RESEND_CANCEL: 'OTP Resend Cancel',
    OTP_VERIFY_FAIL_CANCEL: 'OTP Verify Fail Cancel',
    OTP_SEND_FAIL_OK: 'OTP Send Fail Ok',
    MCQ_START_NEXT: 'MCQ Start Next',
    MCQ_NEXT: 'MCQ Next',
    CRS_NEXT: 'CRS Next',
    CRS_CONFIRMLIABLE_CONFIRM_NO: 'CRS ConfirmLiable ConfirmNo',
    CRS_CONFIRMLIABLE_CONFIRM_YES: 'CRS ConfirmLiable ConfirmYes',
    CREATE_PIN_NEXT: 'Create Pin Next',
    EMAIL_NEXT: 'Email Next',
    EMAIL_SKIP: 'Email Skip',
    PENDING_OK: 'Pending Ok',
    SUSSESSFUL_LOGIN: 'Successful Login',
    SUCCESSFUL_FAQ: 'Successful FAQ',
    UNSUCCESSFUL_OK: 'Un Successful Ok',
    CAPTURE_POR_NEXT: 'POR Next',
    CAPTURE_ADDRESS_NEXT: 'Address Next',
    CAPTURE_ADDRESS_CANCEL: 'Address Cancel',
    CHANGE_ADDRESS: 'Change Address',
    CONFIRM_ADDRESS_NEXT: 'Confirm Address Next',
    TIMEOUT_CONTINUE: 'Continue',
    TIMEOUT_EXIT: 'Exit',
    SUBMIT_INSTANT_APPLICATION: 'Submit instant application',
    GET_INSTANT_COVER: 'Miway Check instant cover',
    NOT_INTERESTED: 'No, I’m not interested',
    NEXT_SANLAM: 'Next Sanlam',
    GOT_IT: 'Got it',
    BACK_LOGIN: 'Back to login page',
    CONFIRM_AGE: 'Confirm Age',
    CANCEL_CONFIRM_AGE: 'Cancel Confirm Age',
    NEXT_MIWAY: 'Next Miway',
    LEARN_MORE_MIWAY: 'Learn more about this benefit',
    CLOSE_MODAL_BENEFIT: 'Close modal benefit miway',
    G_Play_Button: 'Google Play Button',
    A_Store_Button: 'App Store Button',
    H_App_Button: 'Huawei AppGallery Button',
    Web_Button: 'Open an account Web Button',
    Mobile_Web_Button: 'Open an account Mobile Web Button',
    Dynamic_App_Top_Button: 'Dynamic App Top Button',
    Dynamic_App_Top_Middle: 'Dynamic App Top Middle Button',
    FIND_A_KIOSK_NEAR_YOU: 'Find a kiosk near you',
    FIND_A_PNP_OR_BOXER_NEAR_YOU: 'Find a PnP or Boxer near you',
    CONTINUE_ACTIVE: 'Continue',
};
export const MAX_RETRY_ATTEMPTS = 3;

export const RETRY_SCALLING_DURATION = 2000;

export const RETRY_WITH_STATUS_CODES = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];

export const RETRY_WITH_ERROR_CODES = [ERROR_CODES.EMPTY_NAME_MCQ, ERROR_CODES.EMPTY_CREDIT_MCQ];

export const REQUEST_TIMEOUT = 120; // 120 seconds

export const TYMEBANK_TERMS_CONDITIONS = {
    URL: 'https://www.tymebank.co.za/legal/terms-conditions/',
    TEXT: 'www.tymebank.co.za',
};

export const TC_NAMES = {
    ALL: 'all',
    TYMEBANK: 'tymeBank',
    SMARTSHOPPER: 'smartShopper',
    MARKETING: 'marketing',
    SMARTSHOPPERCB: 'smartShopperCB',
    SMARTSHOPPERCB_MARKETING: 'smartShopperCBMarketing',
    MARKETINGCB: 'marketingCB',
};

export const TEXT_BOX_NAMES = {
    EMAIL_ADDRESS: 'emailAddress',
    CONFIRM_EMAIL: 'confirmEmail',
};

export const TYPE_CHECK_ELIGIBILITY = {
    INIT: 0,
    SUCCESSFUL: 1,
    FAILED: -1,
};

export const PROFILE_STATUS = {
    ACTIVE: 'Active',
    PENDING: 'Pending Approval',
};
