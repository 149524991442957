import { createAction } from 'redux-actions';
import {
    // token
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILED,
    // said
    VERIFY_SAID,
    VERIFY_GOOGLE_RECAPTCHA,
    VERIFY_SAID_SUCCESS,
    VERIFY_SAID_FAILED,
    GET_GOOGLE_APP_ACCESS_TOKEN,
    GET_GOOGLE_APP_ACCESS_TOKEN_SUCCESS,
    GET_GOOGLE_APP_ACCESS_TOKEN_FAILED,
    // cellphone
    CAPTURE_CELLPHONE,
    CAPTURE_CELLPHONE_SUCCESS,
    CAPTURE_CELLPHONE_FAILED,
    GENERATE_OTP,
    SHOW_OTP_MODAL,
    GENERATE_OTP_SUCCESS,
    GENERATE_OTP_FAILED,
    RESET_RESEND_OTP_ATTEMPT,
    CAPTURE_OTP,
    CAPTURE_OTP_SUCCESS,
    CAPTURE_OTP_FAILED,
    CAPTURE_INVALID_OTP,
    CAPTURE_OTP_EXPIRED,
    // pull idv
    PULL_ID_RESULTS,
    PULL_ID_RESULTS_SUCCESS,
    PULL_ID_RESULTS_FAILED,
    // mcq
    REQUEST_MCQ_DATA,
    REQUEST_MCQ_DATA_SUCCESS,
    REQUEST_MCQ_DATA_FAILED,
    SAVE_MCQ_DATA,
    CAPTURE_MCQ,
    CAPTURE_MCQ_SUCCESS,
    CAPTURE_MCQ_FAILED,
    // sof
    CAPTURE_SOF,
    CAPTURE_SOF_SUCCESS,
    CAPTURE_SOF_FAILED,

    // crs
    CAPTURE_CRS,
    CAPTURE_CRS_SUCCESS,
    CAPTURE_CRS_FAILED,

    // pin
    GET_TIME_TOKEN,
    GET_TIME_TOKEN_SUCCESS,
    GET_TIME,
    GET_TIME_SUCCESS,
    CAPTURE_PIN,
    CAPTURE_PIN_SUCCESS,
    CAPTURE_PIN_FAILED,

    RESET_STATE,
    GET_ACCESS_TOKEN,
    GET_GOOGLE_TIME,
    GET_ELIGIBILITY_CHECK,
    GET_ELIGIBILITY_CHECK_SUCCESS,
    GET_ELIGIBILITY_CHECK_FAILED,
    CAPTURE_CONSENT,
    CAPTURE_CONSENT_SUCCESS,
    CAPTURE_CONSENT_FAILED,
    CLEAR_CAPTURE_CONSENT_ERROR_CODE,
    POST_CONSENT_RESULT,
    POST_CONSENT_RESULT_SUCCESS,
    POST_CONSENT_RESULT_FAILED,
    UPDATE_MODAL,
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_GENERIC_ERROR_MODAL,

    // T&C
    TC_ACCEPT,
    TC_ACCEPT_SUCCESS,
    TC_ACCEPT_FAILED,
    // EMAIL
    CAPTURE_EMAIL,
    CAPTURE_EMAIL_SUCCESS,
    CAPTURE_EMAIL_FAILED,

    //
    GET_ELIGIBILITY_CHECK_MIWAY,
    GET_ELIGIBILITY_CHECK_MIWAY_SUCCESS,
    GET_ELIGIBILITY_CHECK_MIWAY_FAILED,
    CAPTURE_CONSENT_MIWAY,
    CAPTURE_CONSENT_MIWAY_SUCCESS,
    CAPTURE_CONSENT_MIWAY_FAILED,
    CLEAR_CAPTURE_CONSENT_MIWAY_ERROR_CODE,
    CLEAR_CAPTURE_CELLPHONE_ERROR_CODE,
    CLEAR_CAPTURE_SAID_ERROR_CODE,
} from './types';

export function errorHelper(actionType, error) {
    return {
        type: actionType,
        error,
    };
}

export function resetAllState() {
    return (dispatch) => {
        dispatch({ type: RESET_STATE });
    };
}

export function getAccessToken(callback, access = 'token') {
    return {
        type: GET_ACCESS_TOKEN,
        payload: {
            access,
            callback,
        },
    };
}

export function getGoogleTime(callback) {
    return {
        type: GET_GOOGLE_TIME,
        payload: callback,
    };
}

// Refresh tone
export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);
export const refreshTokenFailed = createAction(REFRESH_TOKEN_FAILED);

// Capture SAID
export const verifySaID = createAction(VERIFY_SAID);
export const verifyRecaptcha = createAction(VERIFY_GOOGLE_RECAPTCHA);
export const verifySaIDSuccess = createAction(VERIFY_SAID_SUCCESS);
export const verifySaIDFailed = createAction(VERIFY_SAID_FAILED);

export const getGoogleAppAccessToken = createAction(GET_GOOGLE_APP_ACCESS_TOKEN);
export const getGoogleAppAccessTokenSuccess = createAction(GET_GOOGLE_APP_ACCESS_TOKEN_SUCCESS);
export const getGoogleAppAccessTokenFailed = createAction(GET_GOOGLE_APP_ACCESS_TOKEN_FAILED);

// Capture cellphone
export const captureCellphone = createAction(CAPTURE_CELLPHONE);
export const captureCellphoneSuccess = createAction(CAPTURE_CELLPHONE_SUCCESS);
export const captureCellphoneFailed = createAction(CAPTURE_CELLPHONE_FAILED);
export const generateOtp = createAction(GENERATE_OTP);
export const showOTPModal = createAction(SHOW_OTP_MODAL);
export const generateOtpSuccess = createAction(GENERATE_OTP_SUCCESS);
export const generateOtpFailed = createAction(GENERATE_OTP_FAILED);
export const resetResendOTPAttempt = createAction(RESET_RESEND_OTP_ATTEMPT);
export const captureOTP = createAction(CAPTURE_OTP);
export const captureOTPSuccess = createAction(CAPTURE_OTP_SUCCESS);
export const captureOTPFailed = createAction(CAPTURE_OTP_FAILED);
export const captureOTPFailedInvalidOTP = createAction(CAPTURE_INVALID_OTP);
export const captureOTPFailedOTPExpired = createAction(CAPTURE_OTP_EXPIRED);

// Pull idv
export const pullIdVerification = createAction(PULL_ID_RESULTS);
export const pullIdvSuccess = createAction(PULL_ID_RESULTS_SUCCESS);
export const pullIdvFailed = createAction(PULL_ID_RESULTS_FAILED);

// Capture mcq
export const requestMcqData = createAction(REQUEST_MCQ_DATA);
export const requestMcqDataSuccess = createAction(REQUEST_MCQ_DATA_SUCCESS);
export const requestMcqDataFailed = createAction(REQUEST_MCQ_DATA_FAILED);
export const saveMcqData = createAction(SAVE_MCQ_DATA);
export const captureMcq = createAction(CAPTURE_MCQ);
export const captureMcqSuccess = createAction(CAPTURE_MCQ_SUCCESS);
export const captureMcqFailed = createAction(CAPTURE_MCQ_FAILED);

// Capture sof
export const captureSof = createAction(CAPTURE_SOF);
export const captureSofSuccess = createAction(CAPTURE_SOF_SUCCESS);
export const captureSofFailed = createAction(CAPTURE_SOF_FAILED);

// Capture crs
export const captureCrs = createAction(CAPTURE_CRS);
export const captureCrsSuccess = createAction(CAPTURE_CRS_SUCCESS);
export const captureCrsFailed = createAction(CAPTURE_CRS_FAILED);

// Capture pin
export const getTimeToken = createAction(GET_TIME_TOKEN);
export const getTimeTokenSuccess = createAction(GET_TIME_TOKEN_SUCCESS);
export const getTime = createAction(GET_TIME);
export const getTimeSuccess = createAction(GET_TIME_SUCCESS);
export const capturePin = createAction(CAPTURE_PIN);
export const capturePinSuccess = createAction(CAPTURE_PIN_SUCCESS);
export const capturePinFailed = createAction(CAPTURE_PIN_FAILED);

// Sanlam check eligibility
export const getEligibilityCheck = createAction(GET_ELIGIBILITY_CHECK);
export const getEligibilityCheckSuccess = createAction(GET_ELIGIBILITY_CHECK_SUCCESS);
export const getEligibilityCheckFailed = createAction(GET_ELIGIBILITY_CHECK_FAILED);
export const captureConsent = createAction(CAPTURE_CONSENT);
export const captureConsentSuccess = createAction(CAPTURE_CONSENT_SUCCESS);
export const captureConsentFailed = createAction(CAPTURE_CONSENT_FAILED);
export const clearCaptureConsentErrorCode = createAction(CLEAR_CAPTURE_CONSENT_ERROR_CODE);
export const postConsentResult = createAction(POST_CONSENT_RESULT);
export const postConsentResultSuccess = createAction(POST_CONSENT_RESULT_SUCCESS);
export const postConsentResultFailed = createAction(POST_CONSENT_RESULT_FAILED);

// Modal
export const updateStack = createAction(UPDATE_MODAL);
export const showModal = createAction(SHOW_MODAL);
export const hideModal = createAction(HIDE_MODAL);
export const showGenericErrorModal = createAction(SHOW_GENERIC_ERROR_MODAL);

// T&C
export const tCAccept = createAction(TC_ACCEPT);
export const tCAcceptSuccess = createAction(TC_ACCEPT_SUCCESS);
export const tCAcceptFailed = createAction(TC_ACCEPT_FAILED);

// Email
export const captureEmail = createAction(CAPTURE_EMAIL);
export const captureEmailSuccess = createAction(CAPTURE_EMAIL_SUCCESS);
export const captureEmailFailed = createAction(CAPTURE_EMAIL_FAILED);

// Miway Insurance
export const getEligibilityCheckMiway = createAction(GET_ELIGIBILITY_CHECK_MIWAY);
export const getEligibilityCheckMiwaySuccess = createAction(GET_ELIGIBILITY_CHECK_MIWAY_SUCCESS);
export const getEligibilityCheckMiwayFailed = createAction(GET_ELIGIBILITY_CHECK_MIWAY_FAILED);
export const captureConsentMiway = createAction(CAPTURE_CONSENT_MIWAY);
export const captureConsentMiwaySuccess = createAction(CAPTURE_CONSENT_MIWAY_SUCCESS);
export const captureConsentMiwayFailed = createAction(CAPTURE_CONSENT_MIWAY_FAILED);
export const clearCaptureConsentMiwayErrorCode = createAction(CLEAR_CAPTURE_CONSENT_MIWAY_ERROR_CODE);

// Clear ErrorCode
export const clearCaptureCellPhoneErrorCode = createAction(CLEAR_CAPTURE_CELLPHONE_ERROR_CODE);
export const clearCaptureSAIDErrorCode = createAction(CLEAR_CAPTURE_SAID_ERROR_CODE);
