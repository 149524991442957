import { handleActions } from 'redux-actions';
import {
    CAPTURE_EMAIL,
    CAPTURE_EMAIL_SUCCESS,
    CAPTURE_EMAIL_FAILED,
    SHOW_GENERIC_ERROR_MODAL,
} from 'actions/types';

const initialState = {
    loading: false,
    captureEmailSuccess: false,
    captureEmailFailed: false,
};

const actions = {
    [CAPTURE_EMAIL]: (state) => ({
        ...state,
        loading: true,
        captureEmailFailed: false,
    }),
    [CAPTURE_EMAIL_SUCCESS]: (state) => ({
        ...state,
        captureEmailSuccess: true,
    }),
    [CAPTURE_EMAIL_FAILED]: (state) => ({
        ...state,
        loading: false,
        captureEmailFailed: true,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
