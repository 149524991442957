export const RESET_STATE = 'reset_state';
export const GET_ACCESS_TOKEN = 'get_access_token';
export const GET_ACCESS_TOKEN_SUCCESS = 'get_access_token_success';
export const GET_ACCESS_TOKEN_ERROR = 'get_access_token_error';
export const VERIFY_RECAPTCHA = 'verify_recaptcha';
export const VERIFY_RECAPTCHA_SUCCESS = 'verify_recaptcha_success';
export const VERIFY_RECAPTCHA_ERROR = 'verify_recaptcha_error';
export const MCQ_DATA_REQUEST_ACTION_TYPE = 'mcq_data_request_action_type';
export const MCQ_DATA_REQUEST = 'mcq_data_request';
export const MCQ_DATA_REQUEST_ERROR = 'mcq_data_request_error';
export const MCQ_DATA_SUBMIT_ACTION_TYPE = 'mcq_data_submit_action_type';
export const MCQ_DATA_SUBMIT = 'mcq_data_submit';
export const MCQ_DATA_SUBMIT_ERROR = 'mcq_data_submit_error';
export const MCQ_DATA_SUBMIT_RESET = 'mcq_data_submit_reset';
export const MCQ_SELECTIONS_ACTION_TYPE = 'mcq_selections_action_type';
export const SAID_SUBMIT = 'said_submit';
export const SAID_CAPTURE = 'said_capture';
export const SAID_ERROR = 'said_error';
export const SAID_PULL_RESULTS = 'said_pull_results';
export const SAID_PULL_SUCCESS = 'said_pull_success';
export const SAID_PULL_ERROR = 'said_pull_error';
export const SAID_RESET_PULL_RESULTS = 'said_reset_pull_results';
export const SAID_RESET_STATE = 'said_reset_state';
export const TERMS_ACCEPT = 'terms_accept';
export const TERMS_ACCEPTED = 'terms_accepted';
export const TERMS_ERROR = 'terms_error';
export const TERMS_RESET_STATE = 'terms_reset_state';
export const MSISDN_SUBMIT = 'msisdn_submit';
export const MSISDN_CAPTURE = 'msisdn_capture';
export const MSISDN_ERROR = 'msisdn_error';
export const MSISDN_RESET_STATE = 'msisdn_reset_state';
export const PIN_CREATE = 'pin_create';
export const PIN_CREATED = 'pin_created';
export const PIN_ERROR = 'pin_error';
export const PIN_RESET_STATE = 'pin_reset_state';
export const OTP_GENERATE = 'otp_generate';
export const OTP_GENERATED = 'otp_generated';
export const OTP_GENERATE_ERROR = 'otp_generate_error';
export const OTP_VERIFY = 'otp_verify';
export const OTP_VERIFIED = 'otp_verified';
export const OTP_VERIFY_ERROR = 'otp_verify_error';
export const OTP_RESET_STATE = 'otp_reset_state';
export const SOF_CAPTURE = 'sof_capture';
export const SOF_SUBMIT = 'sof_submit';
export const SOF_ERROR = 'sof_error';
export const EMAIL_CREATE = 'email_create';
export const EMAIL_CREATED = 'email_created';
export const EMAIL_ERROR = 'email_error';
export const EMAIL_RESET_STATE = 'email_reset_state';
export const GET_GOOGLE_TIME = 'get_google_time';
export const GET_GOOGLE_TIME_SUCCESS = 'get_google_time_success';
export const GET_GOOGLE_TIME_ERROR = 'get_google_time_error';
export const CRS_SUBMIT = 'crs_submit';
export const CRS_CAPTURE = 'crs_capture';
export const CRS_ERROR = 'crs_error';

// refresh token
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

// verify SAID
export const VERIFY_SAID = 'VERIFY_SAID';
export const VERIFY_GOOGLE_RECAPTCHA = 'VERIFY_GOOGLE_RECAPTCHA';
export const VERIFY_SAID_SUCCESS = 'VERIFY_SAID_SUCCESS';
export const VERIFY_SAID_FAILED = 'VERIFY_SAID_FAILED';

export const GET_GOOGLE_APP_ACCESS_TOKEN = 'GET_GOOGLE_APP_ACCESS_TOKEN';
export const GET_GOOGLE_APP_ACCESS_TOKEN_SUCCESS = 'GET_GOOGLE_APP_ACCESS_TOKEN_SUCCESS';
export const GET_GOOGLE_APP_ACCESS_TOKEN_FAILED = 'GET_GOOGLE_APP_ACCESS_TOKEN_FAILED';

// Capture cellphone
export const CAPTURE_CELLPHONE = 'CAPTURE_CELLPHONE';
export const CAPTURE_CELLPHONE_SUCCESS = 'CAPTURE_CELLPHONE_SUCCESS';
export const CAPTURE_CELLPHONE_FAILED = 'CAPTURE_CELLPHONE_FAILED';
export const GENERATE_OTP = 'GENERATE_OTP';
export const SHOW_OTP_MODAL = 'SHOW_OTP_MODAL';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';
export const RESET_RESEND_OTP_ATTEMPT = 'RESET_RESEND_OTP_ATTEMPT';
export const CAPTURE_OTP = 'CAPTURE_OTP';
export const CAPTURE_OTP_SUCCESS = 'CAPTURE_OTP_SUCCESS';
export const CAPTURE_OTP_FAILED = 'CAPTURE_OTP_FAILED';
export const CAPTURE_INVALID_OTP = 'CAPTURE_INVALID_OTP';
export const CAPTURE_OTP_EXPIRED = 'CAPTURE_OTP_EXPIRED';

// Pull idv
export const PULL_ID_RESULTS = 'PULL_ID_RESULTS';
export const PULL_ID_RESULTS_SUCCESS = 'PULL_ID_RESULTS_SUCCESS';
export const PULL_ID_RESULTS_FAILED = 'PULL_ID_RESULTS_FAILED';

// Capture mcq
export const REQUEST_MCQ_DATA = 'REQUEST_MCQ_DATA';
export const REQUEST_MCQ_DATA_SUCCESS = 'REQUEST_MCQ_DATA_SUCCESS';
export const REQUEST_MCQ_DATA_FAILED = 'REQUEST_MCQ_DATA_FAILED';
export const SAVE_MCQ_DATA = 'SAVE_MCQ_DATA';
export const CAPTURE_MCQ = 'CAPTURE_MCQ';
export const CAPTURE_MCQ_SUCCESS = 'CAPTURE_MCQ_SUCCESS';
export const CAPTURE_MCQ_FAILED = 'CAPTURE_MCQ_FAILED';

// sof
export const CAPTURE_SOF = 'CAPTURE_SOF';
export const CAPTURE_SOF_SUCCESS = 'CAPTURE_SOF_SUCCESS';
export const CAPTURE_SOF_FAILED = 'CAPTURE_SOF_FAILED';

// crs
export const CAPTURE_CRS = 'CAPTURE_CRS';
export const CAPTURE_CRS_SUCCESS = 'CAPTURE_CRS_SUCCESS';
export const CAPTURE_CRS_FAILED = 'CAPTURE_CRS_FAILED';

// pin
export const GET_TIME_TOKEN = 'GET_TIME_TOKEN';
export const GET_TIME_TOKEN_SUCCESS = 'GET_TIME_TOKEN_SUCCESS';
export const GET_TIME = 'GET_TIME';
export const GET_TIME_SUCCESS = 'GET_TIME_SUCCESS';
export const CAPTURE_PIN = 'CAPTURE_PIN';
export const CAPTURE_PIN_SUCCESS = 'CAPTURE_PIN_SUCCESS';
export const CAPTURE_PIN_FAILED = 'CAPTURE_PIN_FAILED';

// Sanlam check eligibility
export const GET_ELIGIBILITY_CHECK = 'GET_ELIGIBILITY_CHECK';
export const GET_ELIGIBILITY_CHECK_SUCCESS = 'GET_ELIGIBILITY_CHECK_SUCCESS';
export const GET_ELIGIBILITY_CHECK_FAILED = 'GET_ELIGIBILITY_CHECK_FAILED';
export const CAPTURE_CONSENT = 'CAPTURE_CONSENT';
export const CAPTURE_CONSENT_SUCCESS = 'CAPTURE_CONSENT_SUCCESS';
export const CAPTURE_CONSENT_FAILED = 'CAPTURE_CONSENT_FAILED';
export const CLEAR_CAPTURE_CONSENT_ERROR_CODE = 'CLEAR_CAPTURE_CONSENT_ERROR_CODE';
export const POST_CONSENT_RESULT = 'POST_CONSENT_RESULT';
export const POST_CONSENT_RESULT_SUCCESS = 'POST_CONSENT_RESULT_SUCCESS';
export const POST_CONSENT_RESULT_FAILED = 'POST_CONSENT_RESULT_FAILED';

// Modal
export const UPDATE_MODAL = 'UPDATE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_GENERIC_ERROR_MODAL = 'SHOW_GENERIC_ERROR_MODAL';

// T&C
export const TC_ACCEPT = 'TC_ACCEPT';
export const TC_ACCEPT_SUCCESS = 'TC_ACCEPT_SUCCESS';
export const TC_ACCEPT_FAILED = 'TC_ACCEPT_FAILED';

// Email
export const CAPTURE_EMAIL = 'CAPTURE_EMAIL';
export const CAPTURE_EMAIL_SUCCESS = 'CAPTURE_EMAIL_SUCCESS';
export const CAPTURE_EMAIL_FAILED = 'CAPTURE_EMAIL_FAILED';

// Miway Insurance
export const GET_ELIGIBILITY_CHECK_MIWAY = 'GET_ELIGIBILITY_CHECK_MIWAY';
export const GET_ELIGIBILITY_CHECK_MIWAY_SUCCESS = 'GET_ELIGIBILITY_CHECK_MIWAY_SUCCESS';
export const GET_ELIGIBILITY_CHECK_MIWAY_FAILED = 'GET_ELIGIBILITY_CHECK_MIWAY_FAILED';
export const CAPTURE_CONSENT_MIWAY = 'CAPTURE_CONSENT_MIWAY';
export const CAPTURE_CONSENT_MIWAY_SUCCESS = 'CAPTURE_CONSENT_MIWAY_SUCCESS';
export const CAPTURE_CONSENT_MIWAY_FAILED = 'CAPTURE_CONSENT_MIWAY_FAILED';
export const CLEAR_CAPTURE_CONSENT_MIWAY_ERROR_CODE = 'CLEAR_CAPTURE_CONSENT_MIWAY_ERROR_CODE';

// Clear ErrorCode
export const CLEAR_CAPTURE_CELLPHONE_ERROR_CODE = 'CLEAR_CAPTURE_CELLPHONE_ERROR_CODE';
export const CLEAR_CAPTURE_SAID_ERROR_CODE = 'CLEAR_CAPTURE_SAID_ERROR_CODE';
