/* eslint-disable no-unused-vars */
import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { TC_ACCEPT } from 'actions/types';
import { tCAcceptSuccess, showGenericErrorModal, hideModal } from 'actions/index';
import { getErrorCode, dataResponse } from 'classes/helpers';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const tCAcceptEpic = (action$, store, { tCAccept }) =>
    action$.ofType(TC_ACCEPT).switchMap((action) => {
        const { smartShopperCB, marketingCB } = action.payload;

        const params = {
            sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
            appCode: sessionStorage.getItem('appCode'),
            tncItems: [
                {
                    tncResponse: smartShopperCB,
                    tncType: 'Smart Shopper',
                },
                {
                    tncResponse: true,
                    tncType: 'TymeDigital',
                },
            ],
            marketingConsents: [
                {
                    marketingConsentType: 'SmartShopper',
                    marketingConsentResponse: marketingCB,
                },
                {
                    marketingConsentType: 'TymeDigital',
                    marketingConsentResponse: marketingCB,
                },
            ],
        };
        return tCAccept(params)
            .map(() => {
                return tCAcceptSuccess();
            })
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, TC_ACCEPT);
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            });
    });

export default combineEpics(tCAcceptEpic);
