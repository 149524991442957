const button = `
    min-width: 210px;
    height: 48px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    color: @gray2;
    padding: 0 25px;
    border: 1px solid black;
    border-radius: 100px;
    &:focus {
        outline:0;
    }
    @media (max-width: 768px) {
        margin: 0 auto;
        height: 40px;
        line-height: 38px;
        font-size: 14px;
    }
`;

const solidButton = ({ theme, disabled }) => `
    ${button}
    height: 48px;
    font-size: 16px;
    line-height: 46px;
    color: #333333;
    text-shadow: none;
    background: ${disabled ? theme.colors.yellowLighter : theme.colors.yellow};
    border-color: transparent;
    transition: background-color 0.3s ease-in-out;
    opacity: ${disabled ? 0.65 : 1};
    text-shadow: ${disabled ? `-1px -1px 1px ${theme.colors.white}` : 'none'};
    &:hover {
        cursor: ${disabled ? 'not-allowed;' : 'pointer'}
    }
`;

const contourButton = `
    ${button}
    transition: box-shadow 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-color: transparent;
    color: black;
    text-shadow: none;
    box-sizing: border-box;
`;

const whiteButton = `
    ${button}
    transition: box-shadow 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
    background-color: transparent;
    color: white;
    border: 1px solid white;
    text-shadow: none;
    box-sizing: border-box;
`;

const mixins = {
    button,
    solidButton,
    contourButton,
    whiteButton,
};
export default mixins;
