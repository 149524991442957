import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    SOF_CAPTURE,
    SOF_SUBMIT,
    SOF_ERROR,
    MCQ_DATA_REQUEST_ACTION_TYPE,
    MCQ_DATA_REQUEST,
    MCQ_DATA_REQUEST_ERROR,
    MCQ_DATA_SUBMIT_ACTION_TYPE,
    MCQ_DATA_SUBMIT,
    MCQ_DATA_SUBMIT_ERROR,
    MCQ_DATA_SUBMIT_RESET,
} from '../../actions/types';
import { generateRetryStrategy } from '../../classes/helpers';

export const mcqDataRequest = (args, callback) => ({ type: MCQ_DATA_REQUEST_ACTION_TYPE, payload: { args, callback } });

export const mcqDataRequestEpic = (action$) =>
    action$.ofType(MCQ_DATA_REQUEST_ACTION_TYPE)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.mcq}/${Config.apiVersion}${Config.apiUri.mcqGet}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);
                    return {
                        type: MCQ_DATA_REQUEST,
                        payload: response,
                    };
                })
                .retryWhen(generateRetryStrategy())
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(MCQ_DATA_REQUEST_ERROR, xhr));
                }));


export const submitSof = (args, callback) => ({ type: SOF_SUBMIT, payload: { args, callback } });

export const submitSofEpic = (action$) =>
    action$.ofType(SOF_SUBMIT)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.sofCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                    sourceOfFunds: action.payload.args.sourceOfFunds,
                    valueOfFunds: action.payload.args.valueOfFunds,
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: SOF_CAPTURE,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(SOF_ERROR, xhr));
                }));


export const mcqDataSubmit = (args, callback) => ({ type: MCQ_DATA_SUBMIT_ACTION_TYPE, payload: { args, callback } });

export const mcqDataSubmitEpic = (action$) =>
    action$.ofType(MCQ_DATA_SUBMIT_ACTION_TYPE)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.mcq}/${Config.apiVersion}${Config.apiUri.mcqCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                    nameAnswer: action.payload.args.nameAnswer,
                    creditAnswers: action.payload.args.creditAnswers,
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: MCQ_DATA_SUBMIT,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(MCQ_DATA_SUBMIT_ERROR, xhr));
                }));

export const resetMcqDataSubmit = () => ({ type: MCQ_DATA_SUBMIT_RESET });
