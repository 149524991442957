import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { CAPTURE_CELLPHONE, GENERATE_OTP, CAPTURE_OTP, PULL_ID_RESULTS } from 'actions/types';
import {
    captureCellphoneSuccess,
    captureCellphoneFailed,
    showGenericErrorModal,
    generateOtp,
    generateOtpSuccess,
    captureOTPSuccess,
    captureOTPFailedInvalidOTP,
    captureOTPFailedOTPExpired,
    hideModal,
    pullIdvSuccess,
    pullIdvFailed,
} from 'actions/index';
import { getErrorCode, generateRetryStrategy, dataResponse } from 'classes/helpers';
import { ERROR_CODES } from 'utils/constants';
import { trackingModal } from 'utils/withTracker';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const captureCellphoneEpic = (action$, store, { captureCellphone }) =>
    action$.ofType(CAPTURE_CELLPHONE).switchMap((action) =>
        captureCellphone(action.payload)
            .map(() => {
                return captureCellphoneSuccess();
            })
            .catch((error, source) => {
                const errorCode = getErrorCode(error);
                const status = error.status;
                if (status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_CELLPHONE);
                }
                if (status === 0) {
                    return Observable.of(
                        showGenericErrorModal({ errorCode: ERROR_CODES.CONNECTION_TIMEOUT }),
                    );
                }
                switch (errorCode) {
                    case ERROR_CODES.REQUIRE_CAPTURE_OTP:
                        const cell = action.payload;
                        const requestToken = error.xhr
                            ? error.xhr.getResponseHeader('request-token')
                            : '';
                        sessionStorage.setItem('request-token', requestToken);
                        return Observable.of(generateOtp({ cell, requestToken }));
                    case ERROR_CODES.INVALID_CELLPHONE_FORMAT:
                        return Observable.of(captureCellphoneFailed(errorCode));
                    case ERROR_CODES.EXISTING_CELLPHONE:
                        return Observable.of(captureCellphoneFailed(errorCode));
                    default:
                        return Observable.of(showGenericErrorModal({ errorCode }));
                }
            }),
    );

export const generateOtpEpic = (action$, store, { generateOtp }) =>
    action$.ofType(GENERATE_OTP).switchMap((action) => {
        const { cell, requestToken } = action.payload;
        return generateOtp(cell, requestToken)
            .map(() => generateOtpSuccess())
            .catch((error, source) => {
                const errorCode = getErrorCode(error);
                const status = error.status;
                if (status === 401) {
                    return generateRefreshTokenStrategy(action$, source, GENERATE_OTP);
                }
                if (status === 0) {
                    return Observable.of(
                        showGenericErrorModal({ errorCode: ERROR_CODES.CONNECTION_TIMEOUT }),
                    );
                }
                return Observable.of(showGenericErrorModal({ errorCode }));
            });
    });

export const captureOtpEpic = (action$, store, { captureOTP }) =>
    action$.ofType(CAPTURE_OTP).switchMap((action) => {
        const { otpCode, requestToken } = action.payload;
        return captureOTP(otpCode, requestToken)
            .map((response) => {
                const data = dataResponse(get(response, 'xhr.response', '')) || {};
                const stepUpToken = data.stepUpToken;
                sessionStorage.setItem('stepUpToken', stepUpToken);
                return captureOTPSuccess();
            })
            .catch((error, source) => {
                const errorCode = getErrorCode(error);
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_OTP);
                }
                switch (errorCode) {
                    case ERROR_CODES.INVALID_OTP:
                        return Observable.of(captureOTPFailedInvalidOTP());
                    case ERROR_CODES.VERIFY_OTP_EXPIRED:
                        return Observable.of(captureOTPFailedOTPExpired());
                    default:
                        return Observable.of(showGenericErrorModal({ errorCode }));
                }
            });
    });

export const handleDecisionCode = (decisionCode, idvResult, pullType) => {
    switch (decisionCode) {
        case ERROR_CODES.IDV_VERIFIED:
            return pullIdvSuccess({ idvResult, pullType });
        case ERROR_CODES.SAID_IDV_RETRY:
            return pullIdvFailed({ decisionCode });
        default:
            return showGenericErrorModal({ errorCode: decisionCode });
    }
};

export const pullIdvEpic = (action$, store, { pullIdvResult }) =>
    action$.ofType(PULL_ID_RESULTS).switchMap(({ payload: pullType }) =>
        pullIdvResult()
            .mergeMap((response) => {
                const { decisionCode, details } =
                    dataResponse(get(response, 'xhr.response', '')) || {};
                trackingModal(pullType, decisionCode);
                return Observable.of(
                    hideModal(), // hide loading modal
                    handleDecisionCode(decisionCode, details, pullType),
                );
            })
            .retryWhen(generateRetryStrategy())
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, PULL_ID_RESULTS);
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export default combineEpics(captureCellphoneEpic, generateOtpEpic, captureOtpEpic, pullIdvEpic);
