import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    VERIFY_RECAPTCHA,
    VERIFY_RECAPTCHA_SUCCESS,
    VERIFY_RECAPTCHA_ERROR,
    // SAID_CAPTURE,
    // SAID_SUBMIT,
    // SAID_ERROR,
    SAID_RESET_STATE,
    SAID_PULL_RESULTS,
    SAID_PULL_SUCCESS,
    SAID_PULL_ERROR,
    SAID_RESET_PULL_RESULTS,
} from '../../actions/types';
import { generateRetryStrategy } from '../../classes/helpers';

export const verifyRecaptcha = (args, callback) => ({ type: VERIFY_RECAPTCHA, payload: { args, callback } });

export const verifyRecaptchaEpic = (action$) =>
    action$.ofType(VERIFY_RECAPTCHA)
        .mergeMap(action =>
            Observable.ajax({
                url: Config.verifyProxy,
                method: 'POST',
                crossDomain: true,
                body: {
                    response: action.payload.args.response,
                    saId: action.payload.args.saId,
                },
                headers: {
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: VERIFY_RECAPTCHA_SUCCESS,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(VERIFY_RECAPTCHA_ERROR, xhr));
                }));

// export const submitSaId = (args, callback) => ({ type: SAID_SUBMIT, payload: { args, callback } });

// export const submitSaIdEpic = (action$) =>
//     action$.ofType(SAID_SUBMIT)
//         .mergeMap(action =>
//             Observable.ajax({
//                 url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.idCapture}`,
//                 method: 'POST',
//                 crossDomain: true,
//                 headers: {
//                     ...Config.headers,
//                     Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
//                     'Correlation-Id': getUuid(),
//                 },
//                 body: {
//                     saId: action.payload.args.saId,
//                 },
//             })
//                 .map((response) => {
//                     action.payload.callback(response);

//                     return {
//                         type: SAID_CAPTURE,
//                         payload: response,
//                     };
//                 })
//                 .catch(({ xhr }) => {
//                     action.payload.callback(xhr);

//                     return Observable.of(errorHelper(SAID_ERROR, xhr));
//                 }));

export const pullIdResults = (callback) => ({ type: SAID_PULL_RESULTS, payload: callback });

export const pullIdResultsEpic = (action$) =>
    action$.ofType(SAID_PULL_RESULTS)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.aggregation}/${Config.apiVersion}${Config.apiUri.verificationResult}`,
                method: 'GET',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                    appCode: sessionStorage.getItem('appCode'),
                    sessionId: sessionStorage.getItem('sessionId'),
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload(response);

                    return {
                        type: SAID_PULL_SUCCESS,
                        payload: response,
                    };
                })
                .retryWhen(generateRetryStrategy())
                .catch(({ xhr }) => {
                    action.payload(xhr);

                    return Observable.of(errorHelper(SAID_PULL_ERROR, xhr));
                }));

export const resetPullIdResults = () => ({ type: SAID_RESET_PULL_RESULTS });
export const resetState = () => ({ type: SAID_RESET_STATE });

