import {
    VERIFY_RECAPTCHA_SUCCESS,
    VERIFY_RECAPTCHA_ERROR,
    SAID_CAPTURE,
    SAID_ERROR,
    SAID_RESET_STATE,
    SAID_PULL_SUCCESS,
    SAID_PULL_ERROR,
    SAID_RESET_PULL_RESULTS,
} from '../../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
    case VERIFY_RECAPTCHA_SUCCESS:
        return { ...state, recaptchaData: action.payload, recaptchaError: undefined };
    case VERIFY_RECAPTCHA_ERROR:
        return { ...state, recaptchaData: undefined, recaptchaError: action.error };
    case SAID_CAPTURE:
        return { ...state, data: action.payload, error: undefined };
    case SAID_ERROR:
        return { ...state, data: undefined, error: action.error };
    case SAID_PULL_SUCCESS:
        return { ...state, pullData: action.payload, error: undefined };
    case SAID_PULL_ERROR:
        return { ...state, pullData: undefined, pullError: action.error };
    case SAID_RESET_PULL_RESULTS:
        delete state.pullData;
        delete state.pullError;
        return state;
    case SAID_RESET_STATE:
        state = {};
        return state;
    default:
        return state;
    }
}
