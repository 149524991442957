import { handleActions } from 'redux-actions';
import {
    VERIFY_SAID,
    VERIFY_GOOGLE_RECAPTCHA,
    VERIFY_SAID_SUCCESS,
    VERIFY_SAID_FAILED,
    SHOW_GENERIC_ERROR_MODAL,
    CLEAR_CAPTURE_SAID_ERROR_CODE,
} from 'actions/types';

const initialState = {
    sessionId: null,
    appCode: '',
    token: {},
    loading: false,
    success: false,
    failed: false,
    errorCode: undefined,
};

const actions = {
    [VERIFY_SAID]: (state) => ({
        ...state,
        loading: true,
        failed: false,
        errorCode: undefined,
    }),
    [VERIFY_GOOGLE_RECAPTCHA]: (state) => ({
        ...state,
        loading: true,
    }),
    [VERIFY_SAID_SUCCESS]: (
        state,
        {
            payload: {
                origination: { sessionId, appCode },
                token,
            },
        },
    ) => ({
        ...state,
        sessionId,
        appCode,
        token,
        loading: false,
        success: true,
        errorCode: undefined,
    }),
    [VERIFY_SAID_FAILED]: (state, { payload }) => ({
        ...state,
        loading: false,
        failed: true,
        errorCode: payload,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
    [CLEAR_CAPTURE_SAID_ERROR_CODE]: (state) => ({
        ...state,
        errorCode: undefined,
    }),
};

export default handleActions(actions, initialState);
