import {
    MCQ_DATA_REQUEST,
    MCQ_DATA_REQUEST_ERROR,
    MCQ_DATA_SUBMIT,
    MCQ_DATA_SUBMIT_ERROR,
    MCQ_DATA_SUBMIT_RESET,
} from '../../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
    case MCQ_DATA_REQUEST:
        return { ...state, data: action.payload, error: undefined };
    case MCQ_DATA_REQUEST_ERROR:
        return { ...state, data: undefined, error: action.error };
    case MCQ_DATA_SUBMIT:
        return { ...state, response: action.payload, error: undefined };
    case MCQ_DATA_SUBMIT_ERROR:
        return { ...state, response: undefined, error: action.error };
    case MCQ_DATA_SUBMIT_RESET:
        delete state.response;
        delete state.error;
        return state;
    default:
        return state;
    }
}
