import { handleActions } from 'redux-actions';
import { GET_DECISION } from 'utils/constants';
import {
    SHOW_GENERIC_ERROR_MODAL,
    PULL_ID_RESULTS,
    PULL_ID_RESULTS_SUCCESS,
    PULL_ID_RESULTS_FAILED,
} from '../actions/types';

const initialState = {
    loading: false,
    pullIdvSuccess: false,
};

const actions = {
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
    [PULL_ID_RESULTS]: (state) => ({
        ...state,
        loading: true,
        pullIdvSuccess: false,
    }),
    [PULL_ID_RESULTS_SUCCESS]: (state, { payload: { idvResult = {}, pullType } }) => ({
        ...state,
        loading: false,
        pullIdvSuccess: pullType === GET_DECISION.PULL_VERIFICATION_RESULT_3,
        idvResult,
    }),
    [PULL_ID_RESULTS_FAILED]: (state) => ({
        ...state,
        loading: false,
        pullIdvSuccess: false,
    }),
};

export default handleActions(actions, initialState);
