import React from 'react';
import Theme from 'styles/theme';

export const withTheme = (WrappedComponent) => {
    const ThemeWrapper = (props) => {
        return (
            <Theme>
                <WrappedComponent {...props} />
            </Theme>
        );
    };
    return ThemeWrapper;
};
export default withTheme;
