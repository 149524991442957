import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    CRS_CAPTURE,
    CRS_SUBMIT,
    CRS_ERROR,
} from '../../actions/types';

export const submitCrs = (args, callback) => ({ type: CRS_SUBMIT, payload: { args, callback } });

export const submitCrsEpic = (action$) =>
    action$.ofType(CRS_SUBMIT)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.crsCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                    crsResponse: action.payload.args.crsResponse,
                    incomeTaxRegions: action.payload.args.incomeTaxRegions,
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: CRS_CAPTURE,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(CRS_ERROR, xhr));
                }));
