import { Observable } from 'rxjs';
import get from 'lodash/get';
import { combineEpics } from 'redux-observable';
import { GET_TIME_TOKEN, GET_TIME, CAPTURE_PIN } from 'actions/types';
import { getTime, getTimeSuccess, capturePinSuccess, showGenericErrorModal } from 'actions/index';
import { getErrorCode, dataResponse } from 'classes/helpers';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const getTimeTokenEpic = (action$, store, { refreshTimeToken }) =>
    action$.ofType(GET_TIME_TOKEN).mergeMap(() =>
        refreshTimeToken()
            .map((res) => {
                const data = dataResponse(get(res, 'xhr.response', {}));
                const token = data.access_token;
                if (token) {
                    sessionStorage.setItem('timeAccessToken', token);
                    return getTime();
                }
                return showGenericErrorModal();
            })
            .catch((error) => {
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export const getTimeEpic = (action$, store, { getTime }) =>
    action$.ofType(GET_TIME).mergeMap(() =>
        getTime()
            .map((res) => {
                const time = dataResponse(get(res, 'xhr.response', {}));
                return getTimeSuccess(time);
            })
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, GET_TIME, 'time');
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export const capturePinEpic = (action$, store, { capturePin }) =>
    action$.ofType(CAPTURE_PIN).mergeMap(({ payload }) =>
        capturePin(payload)
            .map(() => capturePinSuccess())
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_PIN);
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export default combineEpics(getTimeTokenEpic, getTimeEpic, capturePinEpic);
