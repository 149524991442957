import React, { Suspense, lazy, useEffect } from 'react';
import { compose } from 'redux';
import { Prompt, Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import withTracker from 'utils/withTracker';
import withTheme from 'utils/withTheme';
import Loading from 'components/loading';
import Modal from 'components/modal/Modal';
import { EVENT_MAP_CATEGORY } from 'utils/constants';
import { Config } from '../config';

const Wrapper = styled(Box)`
    height: 100%;
`;
const tracker = compose(withTracker, withTheme);

const pages = {
    getStarted: {
        Component: lazy(() => import('containers/get-started/LandingPage')),
        path: '/',
        enhance: tracker,
    },
};

export const Routes = () => {
    useEffect(() => {
        TagManager.initialize(Config.googleTagManager);
        const version = `wo-${Config.environment}-${Config.appVersion}`;
        document.getElementsByTagName('body')[0].setAttribute('data-version', version);

        window.onbeforeunload = () => {
            const tagManagerArgs = {
                gtmId: Config.googleTagManager.gtmId,
                dataLayer: {
                    event: 'Click-Button',
                    category: EVENT_MAP_CATEGORY[window.location.pathname],
                    action: 'Close Browser Page',
                    label: 'close-browser-page',
                },
                dataLayerName: 'PageDataLayer',
            };
            TagManager.dataLayer(tagManagerArgs);
        };
    }, []);

    const BackBrowser = () => (
        <Prompt
            message={(location, action) => {
                const message = 'Sure you want to exit and start over?\n\nYou’re so close to opening your TymeBank account! If you go back now, you’ll have to start the process all over again.';
                if (action === 'POP') {
                    return message;
                }
                return true;
            }}
        />
    );

    return (
        <Wrapper>
            <BackBrowser />
            <Suspense fallback={<Loading />}>
                <Switch>
                    {Object.keys(pages).map((key) => {
                        const { Component, path, enhance } = pages[key];
                        return (
                            <Route
                                key={key}
                                exact
                                path={path}
                                component={enhance(Component)}></Route>
                        );
                    })}
                    <Redirect to="/" />
                </Switch>
            </Suspense>
            <Modal />
        </Wrapper>
    );
};

export default withRouter(Routes);
