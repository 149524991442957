/* eslint-disable import/no-cycle */
import { getUuid } from '../classes/helpers';

const headers = {
    Timestamp: new Date().toISOString(),
    Channel: 'Channel.Web',
    'Caller-Id': 'Channel.Web',
    'Device-Info': btoa(JSON.stringify({
        device_name: window.navigator.userAgent,
        device_os: '',
        device_id: '',
        sim_info: [],
        mac: '',
        geolocation: '',
    })),
};

let envTokenHeaders = {
    ...headers,
    'Content-Type': 'application/x-www-form-urlencoded',
};
let envAddressTokenHeaders = { ...envTokenHeaders };
let envTimeTokenHeaders = {};

if (!process.env.REACT_APP_PROXY) {
    envTokenHeaders = { ...envTokenHeaders, Authorization: `Basic ${btoa(process.env.REACT_APP_TOKEN_HEADERS)}` };

    envTimeTokenHeaders = { ...envTokenHeaders, Authorization: `Basic ${btoa(process.env.REACT_APP_TIME_TOKEN_HEADERS)}` };

    envAddressTokenHeaders = { ...envTokenHeaders, Authorization: `Basic ${btoa(process.env.REACT_APP_ADDRESS_TOKEN_HEADERS)}` };
}

const BUILD_ID = process.env.REACT_APP_BUILD_ID || 'local';

export const Config = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    appVersion: `${process.env.REACT_APP_VERSION}-${BUILD_ID}`,
    proxyUrl: process.env.REACT_APP_PROXY,
    verifyProxy: process.env.REACT_APP_GOOGLE_RECAPTCHA === 'on' ? process.env.REACT_APP_RECAPTCHA_VERIFY_PROXY : process.env.REACT_APP_VERIFY_PROXY_WITHOUT_RECAPTCHA,
    googleAppTokenUrl: process.env.REACT_APP_GOOGLE_APP_ACCESS_TOKEN,
    tokenHeaders: envTokenHeaders,
    addressTokenHeaders: envAddressTokenHeaders,
    timeTokenHeaders: envTimeTokenHeaders,
    tokenBody: {
        grant_type: 'client_credentials',
        scope: 'device_not_authorized',
    },
    rootUrl: process.env.REACT_APP_API,
    publicContentUrl: process.env.REACT_APP_PUBLIC_CONTENT_URL,
    headers: {
        ...headers,
        'Content-Type': 'application/json',
        'Session-Id': getUuid(),
    },
    totalSteps: 12,
    otpTimer: 180,
    mcqTimer: 90,
    timeoutTimer: 30,
    timeoutTimerWait: 120,
    rsaPinPublicKey: process.env.REACT_APP_RSA_PIN_PUBLIC_KEY,
    transportRsaPublicKey: process.env.REACT_APP_TRANSPORT_RSA_PUBLIC_KEY,
    mobileSalt: process.env.REACT_APP_MOBILE_SALT,
    apiContext: {
        activation: 'acs',
        aggregation: 'ags',
        cellphone: 'cps',
        mcq: 'mcqs',
        origination: 'os',
        otp: 'otp',
        profile: 'ps',
        time: 'ts',
        insurance: 'ins',
        miway: 'miway',
    },
    apiVersion: process.env.REACT_APP_API_VERSION,
    apiUri: {
        idCapture: '/origination/application',
        tncCapture: '/origination/capture-tnc',
        cellphoneCapture: '/cellphone/verify-and-capture',
        otpGenerate: '/otp',
        otpVerify: '/otp/verification/request-token',
        verificationResult: '/aggregation/get-verification-result',
        mcqGet: '/mcq/get-questions',
        mcqCapture: '/mcq/capture-identity-mcq',
        sofCapture: '/origination/capture-sources-of-funds',
        pinCapture: '/profile/capture-profile-pin',
        emailCapture: '/origination/capture-email',
        activateActivation: '/activation/activate',
        passwordActivation: '/activation/password',
        time: '/time',
        crsCapture: '/origination/capture-crs-answer',
        getPoR: '/mcq/pors',
        capturePoR: '/mcq/capture-por-mcq',
        manualAddressValidation: '/customer/manual-address-validation.json',
        addressSAAdministrativeUnits: '/customer/sa-administrative-units.json',
        addressSuggestions: '/maps/place/v1.0.0/autocomplete/json?components=country%3Aza',
        addressDetails: '/maps/place/v1.0.0/details/json?fields=address_components',
        captureAddress: '/origination/capture-customer-address/v2',
        insuranceCheckEligibility: '/insurance/sanlam/eligibility',
        captureConsent: '/insurance/sanlam/consent',
        postConsentResult: '/sanlam/insurance/v1.0.0/v1/complimentary-cover/contract',
        miwayCheckEligibility: '/insurance/miway/eligibility',
        miwayCaptureConsent: '/insurance/miway/consent',
    },
    googleAnalytics: {
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        debug: false,
        gaOptions: {
            cookieDomain: 'none',
        },
    },
    googleTagManager: {
        gtmId: process.env.REACT_APP_GA_GTM_ID,
        dataLayerName: 'PageDataLayer',
    },
    googleRecaptcha: {
        sitekey: process.env.REACT_APP_RECAPTCHA_SITEKEY,
    },
    activationNewUserUrl: process.env.REACT_APP_IB_ACTIVATION_NEW_USER_URL,
    loginUrl: process.env.REACT_APP_IB_LOGIN_URL,
    faqUrl: process.env.REACT_APP_FAQ_URL,
    findAKioskUrl: process.env.REACT_APP_FIND_A_KIOSK_URL,
    pageTitle: {
        getStarted: 'Welcome to TymeBank',
        enterId: 'Enter your ID number | TymeBank',
        termsAndConditions: 'Terms | TymeBank',
        tymeBankTerms: 'TymeBank terms | TymeBank',
        smartShopperTerms: 'PnP Smart Shopper terms | TymeBank',
        enterMsisdn: 'Verify your cellnumber | TymeBank',
        mcqStart: 'Verify your identity | TymeBank',
        mcqSelect: 'Confirm your identity | TymeBank',
        mcqSof: 'Source of funds | TymeBank',
        mcqConfirm: 'Submitting your responses | TymeBank',
        mcqPor: 'Proof of Residence | TymeBank',
        unsuccessful: 'Registration failed | TymeBank',
        createPin: 'Create your PIN | TymeBank',
        email: 'Enter your email | TymeBank',
        pending: 'Almost there | TymeBank',
        successful: 'Success | TymeBank',
        crs: 'Tax registration | TymeBank',
        captureAddress: 'Capture Address | TymeBank',
        insuranceSanlam: 'Insurance Sanlam | TymeBank',
        insuranceMiway: 'Insurance Miway | TymeBank',
    },
    googleRecaptchaEnabled: process.env.REACT_APP_GOOGLE_RECAPTCHA === 'on',
    captureAddressEnabled: process.env.REACT_APP_CAPTURE_ADDRESS === 'on',
    insuranceAppTokenUrl: process.env.REACT_APP_INSURANCE_APP_ACCESS_TOKEN,
    insuranceSanlamEnabled: process.env.REACT_APP_INSURANCE_SANLAM === 'on',
    insuranceContractUrl: process.env.REACT_APP_INSURANCE_POST_CONTRACT,
    smartAppUrl: process.env.REACT_APP_SMART_APP_URL,
    iosAppUrl: process.env.REACT_APP_IOS_STORE_URL,
    androidAppUrl: process.env.REACT_APP_ANDROID_STORE_URL,
    huaweiAppUrl: process.env.REACT_APP_HUAWEI_STORE_URL,
    smartAppMarket: process.env.REACT_APP_SMART_APP_MARKET,
    privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY,
    insuranceMiwayEnabled: process.env.REACT_APP_INSURANCE_MIWAY === 'on',
    learnMoreInsuranceBenefitUrl: process.env.REACT_APP_INSURANCE_BENEFIT_URL,
};
