import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { CAPTURE_CRS } from 'actions/types';
import { captureCrsSuccess, showGenericErrorModal } from 'actions/index';
import { getErrorCode } from 'classes/helpers';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const captureCrsEpic = (action$, store, { captureCrs }) =>
    action$.ofType(CAPTURE_CRS).mergeMap((action) =>
        captureCrs(action.payload)
            .map(() => captureCrsSuccess())
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, CAPTURE_CRS);
                }
                const errorCode = getErrorCode(error);
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export default combineEpics(captureCrsEpic);
