import {
    SOF_CAPTURE,
    SOF_ERROR,
} from '../../actions/types';

export default function (state = {}, action) {
    switch (action.type) {
    case SOF_CAPTURE:
        return { ...state, response: action.payload, error: undefined };
    case SOF_ERROR:
        return { ...state, response: undefined, error: action.error };
    default:
        return state;
    }
}
