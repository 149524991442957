const colors = {
    brown: '#7d7163',
    yellow: '#fc0',
    yellowLighter: '#f0da82',
    blueDark: '#035',
    brightBlue: '#4AB8E4',
    cyan: '#5ca5b5',
    turquoise: '#66b587',
    gray: '#231f20',

    red: '#ff0000',
    green: '#009900',
    blue: '#0066cc',
    blueOregon: '#4A90E2',
    white: '#FFFFFF',
    black: 'black',

    blue2: '#0085C1',

    gray1: 'lighten(black, 10%)',
    gray2: 'lighten(black, 20%)',
    gray3: 'lighten(black, 30%)',
    gray4: '#666',
    gray5: 'lighten(black, 50%)',
    gray6: 'lighten(black, 60%)',
    gray7: 'lighten(black, 70%)',
    gray8: 'lighten(black, 80%)',
    gray9: '#e6e6e6',
    gray10: '#f5f5f5',
    gray11: '#F3F5F7',
    gray12: '#6B6B6B',
    gray13: '#E8E8E8',
    gray14: '#EEE',

    shadowBoxInsurance: '0px -1px 8px rgba(0, 0, 0, 0.1)',
    opacityDescription: 'rgba(0, 0, 0, 0.4)',
};

export const breakpoints = {
    xs: 375,
    sm: 768,
    md: 992,
    lg: 1300,
};

export default {
    colors,
};
