import i18n from 'i18next';
import translationEN from 'locales/en/translation.json';

const resources = {
    eng: {
        translation: translationEN,
    },
};

i18n.init({
    resources,
    lng: 'eng',
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        wait: true,
    },
});

export default i18n;
