import { handleActions } from 'redux-actions';
import { TYPE_CHECK_ELIGIBILITY } from 'utils/constants';
import {
    GET_ELIGIBILITY_CHECK,
    GET_ELIGIBILITY_CHECK_SUCCESS,
    GET_ELIGIBILITY_CHECK_FAILED,
    CAPTURE_CONSENT,
    CAPTURE_CONSENT_SUCCESS,
    CAPTURE_CONSENT_FAILED,
    POST_CONSENT_RESULT,
    POST_CONSENT_RESULT_SUCCESS,
    POST_CONSENT_RESULT_FAILED,
} from 'actions/types';

const initialState = {
    loading: false,
    insuranceAppAccessToken: '',
    checkEligibilitySuccess: TYPE_CHECK_ELIGIBILITY.INIT,
    checkEligibilityFailed: false,
    errorCode: '',
    captureConsentSuccess: false,
    captureConsentFailed: false,
    postConsentResultSuccess: false,
    postConsentResultFailed: false,
};

const actions = {
    [GET_ELIGIBILITY_CHECK]: state => ({
        ...state,
        loading: true,
        checkEligibilityFailed: false,
    }),
    [GET_ELIGIBILITY_CHECK_SUCCESS]: (state, { payload }) => ({
        ...state,
        loading: false,
        insuranceAppAccessToken: payload.token.access_token,
        checkEligibilitySuccess: TYPE_CHECK_ELIGIBILITY.SUCCESSFUL,
    }),
    [GET_ELIGIBILITY_CHECK_FAILED]: state => ({
        ...state,
        loading: false,
        insuranceAppAccessToken: '',
        checkEligibilitySuccess: TYPE_CHECK_ELIGIBILITY.FAILED,
        checkEligibilityFailed: true,
    }),
    [CAPTURE_CONSENT]: state => ({
        ...state,
        loading: true,
    }),
    [CAPTURE_CONSENT_SUCCESS]: (state) => ({
        ...state,
        captureConsentSuccess: true,
    }),
    [CAPTURE_CONSENT_FAILED]: (state, { payload }) => ({
        ...state,
        captureConsentSuccess: false,
        captureConsentFailed: true,
        errorCode: payload,
        loading: false,
    }),
    [POST_CONSENT_RESULT]: state => ({
        ...state,
    }),
    [POST_CONSENT_RESULT_SUCCESS]: (state) => ({
        ...state,
        postConsentResultSuccess: true,
        postConsentResultFailed: false,
        loading: false,
    }),
    [POST_CONSENT_RESULT_FAILED]: (state, { payload }) => ({
        ...state,
        postConsentResultSuccess: false,
        postConsentResultFailed: true,
        errorCode: payload,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
