import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    EMAIL_CREATE,
    EMAIL_CREATED,
    EMAIL_ERROR,
    EMAIL_RESET_STATE,
} from '../../actions/types';

export const createEmail = (args, callback) => ({ type: EMAIL_CREATE, payload: { args, callback } });

export const createEmailEpic = (action$) =>
    action$.ofType(EMAIL_CREATE)
        .mergeMap(action =>
            Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.emailCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                    emailAddress: action.payload.args.email,
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: EMAIL_CREATED,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(EMAIL_ERROR, xhr));
                }));

export const resetState = () => ({ type: EMAIL_RESET_STATE });
