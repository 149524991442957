import { Observable } from 'rxjs/Observable';
import { errorHelper } from '../../actions';
import { Config } from '../../config';
import {
    TERMS_ACCEPT,
    TERMS_ACCEPTED,
    TERMS_ERROR,
    TERMS_RESET_STATE,
} from '../../actions/types';

export const acceptTerms = (args, callback) => ({ type: TERMS_ACCEPT, payload: { args, callback } });

export const acceptTermsEpic = (action$) =>
    action$.ofType(TERMS_ACCEPT)
        .mergeMap(action => {
            let smartShopperMarketing;
            let tymeBankMarketing;

            if (action.payload.args.marketing && action.payload.args.smartShopper) {
                if (action.payload.args.smartShopperMarketing === undefined) {
                    smartShopperMarketing = true;
                    tymeBankMarketing = true;
                } else {
                    ({ smartShopperMarketing } = action.payload.args);
                    tymeBankMarketing = true;
                }
            } else if (action.payload.args.marketing && !action.payload.args.smartShopper) {
                ({ smartShopperMarketing } = action.payload.args);
                tymeBankMarketing = true;
            } else if (!action.payload.args.marketing && action.payload.args.smartShopper) {
                if (action.payload.args.smartShopperMarketing === undefined) {
                    smartShopperMarketing = false;
                    ({ tymeBankMarketing } = action.payload.args);
                } else {
                    ({ smartShopperMarketing } = action.payload.args);
                    ({ tymeBankMarketing } = action.payload.args);
                }
            } else if (!action.payload.args.marketing && !action.payload.args.smartShopper) {
                ({ smartShopperMarketing } = action.payload.args);
                ({ tymeBankMarketing } = action.payload.args);
            }

            return Observable.ajax({
                url: `${Config.rootUrl}/${Config.apiContext.origination}/${Config.apiVersion}${Config.apiUri.tncCapture}`,
                method: 'POST',
                crossDomain: true,
                headers: {
                    ...Config.headers,
                    Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
                    'Correlation-Id': sessionStorage.getItem('correlationId'),
                },
                body: {
                    sessionId: parseInt(sessionStorage.getItem('sessionId'), 10),
                    appCode: sessionStorage.getItem('appCode'),
                    tncItems: [
                        {
                            tncResponse: action.payload.args.smartShopper,
                            tncType: 'Smart Shopper',
                        },
                        {
                            tncResponse: action.payload.args.tymeBank,
                            tncType: 'TymeDigital',
                        },
                    ],
                    marketingConsents: [
                        {
                            marketingConsentType: 'SmartShopper',
                            marketingConsentResponse: smartShopperMarketing,
                        },
                        {
                            marketingConsentType: 'TymeDigital',
                            marketingConsentResponse: tymeBankMarketing,
                        },
                    ],
                },
                responseType: 'xml',
            })
                .map((response) => {
                    action.payload.callback(response);

                    return {
                        type: TERMS_ACCEPTED,
                        payload: response,
                    };
                })
                .catch(({ xhr }) => {
                    action.payload.callback(xhr);

                    return Observable.of(errorHelper(TERMS_ERROR, xhr));
                });
        });

export const resetState = () => ({ type: TERMS_RESET_STATE });
