import { handleActions } from 'redux-actions';
import {
    REQUEST_MCQ_DATA,
    REQUEST_MCQ_DATA_SUCCESS,
    SAVE_MCQ_DATA,
    CAPTURE_MCQ,
    CAPTURE_MCQ_SUCCESS,
    CAPTURE_MCQ_FAILED,
    CAPTURE_SOF,
    CAPTURE_SOF_SUCCESS,
    SHOW_GENERIC_ERROR_MODAL,
} from 'actions/types';

const initialState = {
    loading: false,
    nameMcqs: undefined,
    creditMcqs: undefined,
    mcqData: {},
    captureMcqSuccess: false,
    captureMcqFailed: false,
    captureSofSuccess: false,
};

const actions = {
    [REQUEST_MCQ_DATA]: (state) => ({
        ...state,
        loading: true,
        nameMcqs: null,
        creditMcqs: null,
    }),
    [REQUEST_MCQ_DATA_SUCCESS]: (state, { payload: { nameMcqs, creditMcqs } }) => ({
        ...state,
        loading: false,
        nameMcqs,
        creditMcqs,
    }),
    [SAVE_MCQ_DATA]: (state, { payload }) => ({
        ...state,
        mcqData: payload,
    }),
    [CAPTURE_MCQ]: (state) => ({
        ...state,
        loading: true,
        captureMcqSuccess: false,
        captureMcqFailed: false,
    }),
    [CAPTURE_MCQ_SUCCESS]: (state) => ({
        ...state,
        captureMcqSuccess: true,
    }),
    [CAPTURE_MCQ_FAILED]: (state) => ({
        ...state,
        loading: false,
        captureMcqFailed: true,
    }),
    [CAPTURE_SOF]: (state) => ({
        ...state,
        loading: true,
        captureSofSuccess: false,
    }),
    [CAPTURE_SOF_SUCCESS]: (state) => ({
        ...state,
        loading: false,
        captureSofSuccess: true,
    }),
    [SHOW_GENERIC_ERROR_MODAL]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default handleActions(actions, initialState);
