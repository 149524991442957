import { Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import get from 'lodash/get';
import { VERIFY_SAID } from 'actions/types';
import { verifySaIDSuccess, verifySaIDFailed, showGenericErrorModal } from 'actions/index';
import { getErrorCode, dataResponse } from 'classes/helpers';
import { ERROR_CODES } from 'utils/constants';
import { generateRefreshTokenStrategy } from 'epics/refreshTokenEpic';

export const verifySAIDEpic = (action$, store, { verifySAID }) =>
    action$.ofType(VERIFY_SAID).switchMap((action) =>
        verifySAID(action.payload)
            .map((res) => {
                const data = dataResponse(get(res, 'xhr.response', {}));
                return verifySaIDSuccess(data);
            })
            .catch((error, source) => {
                if (error.status === 401) {
                    return generateRefreshTokenStrategy(action$, source, VERIFY_SAID);
                }
                const errorCode = getErrorCode(error);
                if (errorCode === ERROR_CODES.INVALID_ID_FORMAT || errorCode === ERROR_CODES.EXISTING_SAID) {
                    return Observable.of(verifySaIDFailed(errorCode));
                }
                return Observable.of(showGenericErrorModal({ errorCode }));
            }),
    );

export default combineEpics(verifySAIDEpic);
